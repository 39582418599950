export default {
    isRequired: "حقل مطلوب",
    deliveryTo: "توصيل الي",
    pickupFrom: "تستلم من",
    promocodes: "برموكود",
    add: "أضف",
    pleaseCompleteYourAddress: "يرجى استكمال عنوانك",
    edit: "تعديل",
    complete: "اكمل",
    completeAddress: "اكمل العنوان",
    payWith: "أدفع من خلال ",
    addCardPayment: "اضافه كارت دفع",
    offlinePayment: "كاش",
    change: "تغير",
    paymentCard: "بطاقة ائتمانية",
    addNew: "اضافة",
    selectPaymentMethod: "اختر طريقة الدفع",
    cod: " الدفع عن التوصيل (COD) ",
    masterCard: "Master card",
    visa: "Visa",
    meza: "Meza",
    mada: "Mada",
    city: "مدينة",
    area: "منطقة",
    streetNoOrName: "رقم الشارع. / اسم",
    enterCVV: "أدخل رقم تحقق البطاقة",
    cvvRole: " CVV(CSV) هو اخر 3 ارقام خلف الكارت الخاص بك ",
    cvv: "CVV (CSV) غير صحيح",
    confirm: "تأكيد",
    orderCancelled: "تم إلغاء الطلب بسبب مشكلة في الدفع",
    paymentFailure: "فشل الدفع بسبب خطأ في الاتصال ، يرجى التحقق من اتصالك بالإنترنت والمحاولة مرة أخرى.",
    tryAnotherPaymentMethod: "جرب طريقة دفع أخرى",
    internetConnection: "يرجى التحقق من اتصالك بالإنترنت ، والتأكد من أن البطاقة ليست موجودة بالفعل وحاول مرة أخرى.",
    tryAgain: " حاول مرة أخرى",
    cannotAddCard: "لا يمكن اضافة كارت الدفع",
    buildingNo: "رقم المبنى",
    floorNo: "طابق(اختيار)",
    apartmentNo: "رقم الشقة (اختياري)",
    additionalNotes: "معلومات إضافية (اختياري)",
    addressType: "نوع العنوان",
    companyName: "اسم الشركة (اختياري)",
    save: "حفظ",
    streetNoOrNamePlaceholder: "اكتب رقم الشارع أو اسمه",
    floorNoPlaceholder: "اكتب رقم الطابق",
    apartmentNoPlaceholder: "اكتب رقم الشقة",
    additionalNotesPlaceholder: "اكتب أي معلومات إضافية",
    company: "شركة",
    home: "منزل",
    companyNamePlaceholder: "اكتب اسم الشركة",
    buildingNoPlaceholder: "اكتب رقم المبني",
    minimumOrderValue: "الحد الأدنى للطلب",
    reviewOrder: "مراجعة الطلب",
    delivery: "توصيل",
    pickup: "الاستلام من مطعم",
    required: "حقل مطلوب*",
    additionalNote: "ملاحظات اضافية",
    additionalNotePlaceholder: "انقر لإضافة أي ملاحظات إضافية إلى الطلب ...",
    checkoutButton: "تنفيذ الطلب",
    totalPointsUllGet: "قيمة النقاط التي ستحصل عليها",
    selectOrAddPromocode: "اختر البرموكود الذي تريد إضافته للطلب",
    deliveryTime: "وقت التوصيل",
    pickupTime: "وقت الاستلام",
    ASAP: "في أقرب وقت ممكن",
    giftsAvailable: "هداياك المتاحة",
    viewAll: "مشاهدة المزيد",
    youHave: "لديك",
    and: "و",
    multipleGifts: "هدايا",
    singleGift: "هدية",
    in: "في",
    dineIn: "الاكل في الفرع",
    takeaway: "تيك اواي",
    takeawayTime: "وقت الخدمة",
    dineInTime: "وقت الخدمة",
    cash: "الدفع عند الاستلام",
    tableNumber: "رفم الطاولة",
    pleaseEnterYour: "برجاء إدخال"
};
