import i18n from "../i18n/i18n";
var languageMapping = {
    ar: "ar_name",
    en: "en_name",
};
export var getTranslatedName = function (item) {
    var currentLanguage = i18n.language.split("-")[0];
    var mappedName = languageMapping[currentLanguage];
    return (item[mappedName] || item.en_name || item.ar_name);
};
