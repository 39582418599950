export default {
    delivery: "خدمة التوصيل",
    pickup: "الاستلام من المطعم",
    deliveryDescription: "أريد أن يتم توصيل طعامي إلي",
    pickupDescription: "سأستلم طعامي بنفسي",
    busy: "مشغول",
    closed: "مغلق",
    dineIn: "الاكل في الفرع",
    dineInDescription: "اطلب الطعام الي طاولتك",
    takeaway: "تيك اواي",
};
