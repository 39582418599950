import { action, payload } from "ts-action";
import { getGiftsAction, getPointsAction } from "../constants";
// gifts
export var getGifts = action(getGiftsAction.requested, payload());
export var getGiftsSuccess = action(getGiftsAction.fulfilled, payload());
export var getGiftsFailure = action(getGiftsAction.rejected, payload());
// points
export var getPoints = action(getPointsAction.requested, payload());
export var getPointsSuccess = action(getPointsAction.fulfilled, payload());
export var getPointsFailure = action(getPointsAction.rejected, payload());
