var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getQRBranchStatusAction } from "./../constants";
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getQRBranchStatusSuccess } from "../actions/qr-portal-actions";
export var getQRBranchStatusReducer = withLoadingReducer(reducer({
    qr_dine_in_status: "",
    qr_take_away_status: "",
    store_id: "",
}, on(getQRBranchStatusSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), payload));
})), getQRBranchStatusAction);
