export default {
    giftsList: "Gifts list",
    knowMore: "know more",
    giftsListNote: "Gifts are provided from {{store_name}} on Koinz.",
    addToOrder: "Add to Order",
    singleGift: "Gift",
    multipleGifts: "Gifts",
    tabHere: "Tab here to",
    aboutHowToEarn: "about how to earn them",
};
