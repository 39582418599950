var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from "react";
export var useOrderType = function (history) {
    var _a = __read(useState(""), 2), orderType = _a[0], setOrderType = _a[1];
    useEffect(function () {
        var pathname = history.location.pathname;
        var orderType = pathname.includes("dine-in")
            ? "dine-in"
            : pathname.includes("takeaway")
                ? "takeaway"
                : pathname.includes("delivery")
                    ? "delivery"
                    : pathname.includes("pickup")
                        ? "pickup"
                        : "";
        setOrderType(orderType);
    }, [history]);
    return { orderType: orderType };
};
