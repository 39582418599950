export default {
    wasItEasyToPickupYourOrder: "Was it easy to pickup your order?",
    yes: "Yes",
    no: "No",
    alwaysHappyToServeYou: "Always happy to serve you :)",
    help: "مساعده",
    orderId: "كود الطلب:",
    contactUS: "Contact us",
    facingAProblem: "Facing a problem with your order?",
    cancelOrder: "الغاء الطلب",
    reviewed_your_order: "مراجعة الطلب",
    preparing_your_order: "جاري تحضير طلبك",
    reviewed_your_order_desc: "يقوم الفرع بمراجعة تفاصيل طلبك",
    preparing_your_order_desc: "{{store_name}} يعد الآن طلبك! ",
    false: "تم الغاء طلبك",
    got_picked_up: "تم الاستلام",
    delivered: "تم التوصيل",
    got_picked_up_desc: "تم التوصيل",
    delivered_desc: "delivered",
    out_for_delivery: "خرج للتوصيل",
    ready_for_pickup: "جاهز للاستلام",
    out_for_delivery_desc: "طلبك قد تم تحضيره، {{store_name}} سيقوم بتوصيله إليك",
    ready_for_pickup_desc: "طلبك من {{store_name}} جاهز للاستلام ! أظهر كود الطلب للكاشير واستمتع بطلبك.",
    added_gifts_to_order: "إضافة الهدايا الي الطلب",
    added_gifts_to_order_desc: "إضافة الهدايا التي اخترتها إلى طلبك",
    estimatedPreparationTime: "متوسط وقت التحضير",
    pickupFrom: "الاستلام من",
    viewOnMap: "شاهد الخريطة",
    yourOrderDetails: "تفاصيل طلبك",
    subtotal: "مبلغ إجمالي",
    taxesAppliedAutomatically: "تم اضافه قيمه الضرائب",
    estimated_delivery_time: "متوسط وقت التوصيل",
    within: "في خلال",
    delivery_fees: "مصاريف التوصيل",
    total: "المجموع",
    discount: "الخصم",
    address: "العنوان",
    order_rejected: "تم رفض الطلب",
    order_canceled: "تم الغاء الطلب",
    order_rejected_desc: "نعتذر لك ولكن {{store_name}} لم يستطع قبول طلبك.",
    order_rejected_for_payment_failure: "Rejected because of payment failure",
    order_canceled_desc: "نعتذر لك ولكن {{store_name}} قام بإلغاء طلبك.",
    cancelOrderTitle: "الغاء الطلب؟",
    cancelOrderDesc: "هل انت متأكد من انك تريد انهاء الطلب؟ نأسف لرؤيتك تغادر.",
    keepIt: "اكمل الطلب",
    pm: "مساءً",
    am: "صباحا",
    ongoingOrder: "لا يمكن إرسال طلب جديد قبل إنهاء طلبك الحالي",
    close: "غلق",
    ready: "جاهز",
    completed: "اكتمل",
};
