var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import axios from "axios";
import history from "../../helpers/history";
import { clearPrevOrder } from "../../store/redux-store/actions";
import { store } from "../../store/redux-store/configureStore";
var handleSuccess = function (res) { return res; };
var handleError = function (error) {
    if (error.response.status === 401) {
        store.dispatch(clearPrevOrder());
        sessionStorage.removeItem("last_city_id");
        sessionStorage.removeItem("last_district_id");
        sessionStorage.removeItem("last_branch_id");
        setTimeout(function () {
            var hash = window.location.hash;
            var storeNameRegex = /\#\/([^/]*)\//;
            var _a = __read(hash.match(storeNameRegex) || [], 2), storeName = _a[1];
            if (!storeName) {
                throw new TypeError("URL does not conform to store name regex " + hash);
            }
            history.push("/" + storeName + "/");
            window.location.reload();
        }, 1000);
    }
    throw error;
};
export var createAxiosInstance = function (baseURL) {
    var instance = axios.create({
        baseURL: baseURL,
        headers: {
            "Content-Type": "application/json",
        },
    });
    instance.interceptors.response.use(handleSuccess, handleError);
    return instance;
};
export default createAxiosInstance(process.env.ORDERING_ENDPOINT);
