var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Route, HashRouter } from "react-router-dom";
import { ThemeProvider, CSSReset } from "@chakra-ui/core";
// localization
import i18n from "../src/i18n/i18n";
import { useRtlClass } from "./hooks/useRtlClass";
// styles
import * as styles from "./styles.module.sass";
// components
import { languageCodesForArabic } from "./lib";
// context
import NavigationManagerProvider from "./HOCs/NavigationManager/Context/NavigationManagerProvider";
// HOCs
import { withNavigationManager } from "./HOCs";
import { Loader } from "./shared-components/Loader";
// uuid
import { v4 as uuidv4 } from "uuid";
import { QueryClient, QueryClientProvider } from "react-query";
export var Routes = function (_a) {
    var Component = _a.component, rest = __rest(_a, ["component"]);
    var renderRoute = React.useCallback(function (props) { return React.createElement(Component, __assign({}, props)); }, []);
    return React.createElement(Route, __assign({}, rest, { render: renderRoute }));
};
var queryClient = new QueryClient();
function App() {
    // setting up app language using local lang
    var language = navigator.language;
    React.useEffect(function () {
        if (languageCodesForArabic[language]) {
            i18n.changeLanguage("ar");
        }
        else {
            i18n.changeLanguage("en");
        }
    }, []);
    var rtl = useRtlClass(styles);
    // setting installation id when user is using app for the first time
    React.useEffect(function () {
        var localStorage = window.localStorage;
        var previouslySetInstallationId = localStorage.getItem("installation_id");
        // if no prev value then set one
        if (!previouslySetInstallationId) {
            var installation_id = uuidv4();
            localStorage.setItem("installation_id", installation_id);
        }
    }, [window]);
    // enhancing all app routes components with 'NavigationManager' HOC
    var AppRoutes = React.lazy(function () { return import("./routes"); });
    var enhancedAppRoutes = withNavigationManager(AppRoutes);
    return (React.createElement("div", { className: styles.App + " " + rtl },
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(ThemeProvider, null,
                    React.createElement(HashRouter, null,
                        React.createElement(NavigationManagerProvider, null,
                            React.createElement(Routes, { component: enhancedAppRoutes }))),
                    React.createElement(CSSReset, null))))));
}
export default App;
