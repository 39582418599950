var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { on, reducer } from "ts-action";
import { clearPrevOrder, createPickupOrderSuccess } from "../actions";
import { createPickupOrderAction } from "../constants";
import { withLoadingReducer } from "./withLoadingState";
export var createPickupOrderReducer = withLoadingReducer(reducer({ order_id: "" }, on(clearPrevOrder, function (state, _a) { return (__assign(__assign({}, state), { order_id: "" })); }), on(createPickupOrderSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { order_id: payload.order_id, payment_3ds_url: payload.payment_3ds_url }));
})), createPickupOrderAction);
