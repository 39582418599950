var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { PickupStatus } from "../../../types";
import { getStoreInfoAction } from "../constants";
import { getStoreInfoSuccess } from "../actions/store-actions";
export var storeInfoReducer = withLoadingReducer(reducer({
    store: {
        country_iso_code: "",
        delivery_status: PickupStatus.service_enabled,
        id: "",
        logo_image: "",
        background_color: "",
        name: "",
        opening_time: "",
        pickup_cover_image: "",
        pickup_status: "",
        store_tags: [],
        payment_methods: [],
    },
}, on(getStoreInfoSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { store: payload }));
})), getStoreInfoAction);
