export var OrderTypes;
(function (OrderTypes) {
    OrderTypes["delivery"] = "delivery";
    OrderTypes["pickup"] = "pickup";
})(OrderTypes || (OrderTypes = {}));
export var PickupStatus;
(function (PickupStatus) {
    PickupStatus["service_enabled"] = "service_enabled";
    PickupStatus["service_disabled"] = "service_disabled";
    PickupStatus["closed_for_working_hours"] = "closed_for_working_hours";
})(PickupStatus || (PickupStatus = {}));
export var OrderingPortalStatus;
(function (OrderingPortalStatus) {
    OrderingPortalStatus["publish"] = "publish";
    OrderingPortalStatus["unpublish"] = "unpublish";
})(OrderingPortalStatus || (OrderingPortalStatus = {}));
export var UserTypes;
(function (UserTypes) {
    UserTypes["new"] = "new";
    UserTypes["old"] = "old";
    UserTypes["old_no_email"] = "old_no_email";
})(UserTypes || (UserTypes = {}));
export var Verified;
(function (Verified) {
    Verified[Verified["failed"] = 0] = "failed";
    Verified[Verified["succeed"] = 1] = "succeed";
})(Verified || (Verified = {}));
export var BranchStatus;
(function (BranchStatus) {
    BranchStatus["service_enabled"] = "service_enabled";
    BranchStatus["busy"] = "busy";
    BranchStatus["closed"] = "closed";
})(BranchStatus || (BranchStatus = {}));
export var PromocodeValidationScheme;
(function (PromocodeValidationScheme) {
    PromocodeValidationScheme["notFound"] = "not_found";
})(PromocodeValidationScheme || (PromocodeValidationScheme = {}));
export var OrderStatuses;
(function (OrderStatuses) {
    OrderStatuses["sent"] = "sent";
    OrderStatuses["scheduled"] = "scheduled";
    OrderStatuses["canceled_by_courier"] = "canceled_by_courier";
    OrderStatuses["received"] = "received";
    OrderStatuses["seen"] = "seen";
    OrderStatuses["accepted"] = "accepted";
    OrderStatuses["rejected"] = "rejected";
    OrderStatuses["added_gifts_to_order"] = "added_gifts_to_order";
    OrderStatuses["canceled_by_customer"] = "canceled_by_customer";
    OrderStatuses["canceled_by_agent"] = "canceled_by_agent";
    OrderStatuses["canceled_by_cashier"] = "canceled_by_cashier";
    OrderStatuses["customer_no_show"] = "customer_no_show";
    OrderStatuses["ready_for_pickup"] = "ready_for_pickup";
    OrderStatuses["got_picked_up"] = "got_picked_up";
    OrderStatuses["out_for_delivery"] = "out_for_delivery";
    OrderStatuses["delivered"] = "delivered";
    OrderStatuses["rejected_for_payment_failure"] = "rejected_for_payment_failure";
})(OrderStatuses || (OrderStatuses = {}));
export var PromocodeTypes;
(function (PromocodeTypes) {
    PromocodeTypes["discountValue"] = "discount_value";
    PromocodeTypes["discountPercentage"] = "discount_percentage";
    PromocodeTypes["pointsMultiplier"] = "points_multiplier";
})(PromocodeTypes || (PromocodeTypes = {}));
export var localStatesHashIds;
(function (localStatesHashIds) {
    localStatesHashIds["checkoutState"] = "1abe95ef-39b7-433d-b98f-534723a0b014";
    localStatesHashIds["idUsedToFetchDeliveryAddresses"] = "8650675e-4f9d-44a3-b928-349f17c87c13";
    localStatesHashIds["selectedZonesState"] = "1f70f580-654a-492f-9243-f46951241365";
    localStatesHashIds["selectedPromotionState"] = "8242a033-7b66-4fb7-aa0f-a7e810afdf73";
})(localStatesHashIds || (localStatesHashIds = {}));
export var ServiceStatus;
(function (ServiceStatus) {
    ServiceStatus["service_enabled"] = "service_enabled";
    ServiceStatus["service_disabled"] = "service_disabled";
})(ServiceStatus || (ServiceStatus = {}));
