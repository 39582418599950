var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { on, reducer } from "ts-action";
import { loading, loadingFailure, loadingSuccess } from "../actions";
import { LoadingStatus } from "./withLoadingState";
export var loadingReducer = reducer({ loading: LoadingStatus.loading }, on(loading, function (state, _a) { return (__assign(__assign({}, state), { loading: LoadingStatus.success })); }), on(loadingFailure, function (state, _a) { return (__assign(__assign({}, state), { loading: LoadingStatus.failed })); }), on(loadingSuccess, function (state, _a) { return (__assign(__assign({}, state), { loading: LoadingStatus.loading })); }));
