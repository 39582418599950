var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import reducer from "./rootReducer";
import { applyMiddleware, compose, createStore, } from "redux";
// The redux dev tools.
import { composeWithDevTools } from "redux-devtools-extension"; // eslint-disable-line
// The redux middlewares.
import createSagaMiddleware from "redux-saga";
// The redux store enhancers.
import { persistStore } from "redux-persist";
// Import the combined sagas.
import rootSaga from "./rootSaga";
import { loadingBarMiddleware } from "react-redux-loading-bar";
import LogRocket from "logrocket";
export var isDev = process.env.NODE_ENV === "development";
// The middlewares to be applied.
var middlewares = [];
// Instantiates the saga middleware.
var sagaMiddleware = createSagaMiddleware();
middlewares.push(
// optimisticUIMiddleware,
sagaMiddleware);
middlewares.push(loadingBarMiddleware({
    promiseTypeSuffixes: ["REQUESTED", "FULFILLED", "REJECTED"],
}));
middlewares.push(LogRocket.reduxMiddleware());
// Combine the middlewares.
var middleware = applyMiddleware.apply(void 0, __spread(middlewares));
var enhancerParams = [middleware];
// Compose Enhancers (With/Without Dev Tools)
var enhancer = isDev
    ? composeWithDevTools.apply(void 0, __spread(enhancerParams)) : compose.apply(void 0, __spread(enhancerParams));
// Import the combined reducer.
export var store = createStore(reducer, enhancer);
export default (function () {
    return new Promise(function (res) {
        persistStore(store, {}, function () {
            res({ store: store });
            sagaMiddleware.run(rootSaga);
        });
    });
});
