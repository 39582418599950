var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from "react";
export var useBaseURL = function () {
    var _a = __read(useState(""), 2), baseURL = _a[0], setBaseURL = _a[1];
    useEffect(function () {
        if (!window)
            return;
        var href = window.location.href;
        var splittedString = href.split("/");
        var indexOfHash = splittedString.indexOf("#");
        var formattedBaseURL = splittedString.slice(0, indexOfHash + 2).join("/");
        setBaseURL(formattedBaseURL);
    }, [window]);
    return baseURL;
};
