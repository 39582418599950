var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { on, reducer } from "ts-action";
import { clearPrevOrder, cancelOrderFailure, cancelOrderSuccess, getOrderStatusesSuccess, getOrderStatusesFailure, } from "../actions";
import { getOrderStatusesAction } from "../constants";
import { withLoadingReducer } from "./withLoadingState";
var initialState = {
    orderStatuses: [],
    order_code: "",
    api_status: 204,
};
export var getOrderStatusesReducer = withLoadingReducer(reducer(initialState, on(clearPrevOrder, function (state, _a) { return (__assign(__assign({}, state), { orderStatuses: [], order_code: "", api_status: 204 })); }), on(getOrderStatusesSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { order_code: payload.order_code, orderStatuses: payload.order_statuses, api_status: state.orderStatuses.length > 0 ? 200 : 204 }));
}), on(cancelOrderSuccess, function (state, _a) {
    return __assign(__assign({}, state), { order_code: state.order_code, api_status: state.orderStatuses.length > 0 ? 200 : 204 });
}), on(cancelOrderFailure, function (state, _a) { return (__assign(__assign({}, state), { order_code: state.order_code, orderStatuses: state.orderStatuses, api_status: state.orderStatuses.length > 0 ? 200 : 204 })); }), on(getOrderStatusesFailure, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { order_code: state.order_code, orderStatuses: state.orderStatuses, api_status: payload }));
})), getOrderStatusesAction);
