export default {
    minimumOrderValue: "minimumOrderValue",
    isRequired: "isRequired",
    deliveryTo: "deliveryTo",
    pickupFrom: "pickupFrom",
    payWith: "payWith",
    addCardPayment: "addCardPayment",
    change: "change",
    paymentCard: "paymentCard",
    offlinePayment: "offlinePayment",
    addNew: "addNew",
    selectPaymentMethod: "selectPaymentMethod",
    cannotAddCard: "cannotAddCard",
    cod: "cod",
    masterCard: "masterCard",
    visa: "visa",
    meza: "meza",
    mada: "mada",
    promocodes: "promocodes",
    add: "add",
    pleaseCompleteYourAddress: "pleaseCompleteYourAddress",
    edit: "edit",
    complete: "complete",
    completeAddress: "completeAddress",
    city: "city",
    area: "area",
    streetNoOrName: "streetNoOrName",
    buildingNo: "buildingNo",
    enterCVV: "enterCVV",
    cvvRole: "cvvRole",
    cvv: "cvv",
    confirm: "confirm",
    orderCancelled: "orderCancelled",
    paymentFailure: "paymentFailure",
    tryAnotherPaymentMethod: "tryAnotherPaymentMethod",
    internetConnection: "internetConnection",
    tryAgain: "tryAgain",
    floorNo: "floorNo",
    apartmentNo: "apartmentNo",
    additionalNotes: "additionalNotes",
    addressType: "addressType",
    companyName: "companyName",
    save: "save",
    streetNoOrNamePlaceholder: "streetNoOrNamePlaceholder",
    floorNoPlaceholder: "floorNoPlaceholder",
    apartmentNoPlaceholder: "apartmentNoPlaceholder",
    additionalNotesPlaceholder: "additionalNotesPlaceholder",
    company: "company",
    home: "home",
    companyNamePlaceholder: "companyNamePlaceholder",
    buildingNoPlaceholder: "buildingNoPlaceholder",
    reviewOrder: "reviewOrder",
    delivery: "delivery",
    pickup: "pickup",
    required: "required",
    additionalNote: "additionalNote",
    additionalNotePlaceholder: "additionalNotePlaceholder",
    checkoutButton: "checkoutButton",
    totalPointsUllGet: "totalPointsUllGet",
    selectOrAddPromocode: "selectOrAddPromocode",
    pickupTime: "pickupTime",
    deliveryTime: "deliveryTime",
    ASAP: "ASAP",
    giftsAvailable: "giftsAvailable",
    viewAll: "viewAll",
    youHave: "youHave",
    and: "and",
    multipleGifts: "multipleGifts",
    singleGift: "singleGift",
    in: "in",
    dineIn: "dineIn",
    takeaway: "takeaway",
    takeawayTime: "takeawayTime",
    dineInTime: "dineInTime",
    cash: "cash",
    tableNumber: "tableNumber",
    pleaseEnterYour: "pleaseEnterYour"
};
