var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
/*****
 * @description providers responsibility is to provide components
 * with NavigationManagerContext, beside handling internal ui global
 * state like progress bar status, and maintaining app's routing
 * customizations (making sure base url is always correct, navigate
 * forward or backward inside app's screens)
 * ******/
import React, { useState } from "react";
// context
import { NavigationManagerContext } from "./NavigationManagerContext";
var NavigationManagerProvider = function (props) {
    // setting navigator progress bar value
    var setProgressBarValue = function (newValue) {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { progressBarValue: newValue })); });
    };
    var pushToNavigationStack = function (newValue) {
        var navigationStackCopy = __spread(navigationStack);
        navigationStackCopy.push(newValue);
        setState(function (prevState) { return (__assign(__assign({}, prevState), { navigationStack: __spread(navigationStackCopy) })); });
    };
    var popNavigationStack = function () {
        var navigationStackCopy = __spread(navigationStack);
        var poppedValue = navigationStackCopy.pop();
        setState(function (prevState) { return (__assign(__assign({}, prevState), { navigationStack: __spread(navigationStackCopy) })); });
        return poppedValue;
    };
    var _a = __read(useState({
        progressBarValue: 0,
        storeNameIdentifier: "",
        navigationStack: [],
        appBaseUrl: "",
    }), 2), state = _a[0], setState = _a[1];
    var navigationStack = state.navigationStack, progressBarValue = state.progressBarValue;
    return (React.createElement(NavigationManagerContext.Provider, { value: {
            navigationStack: navigationStack,
            popNavigationStack: popNavigationStack,
            progressBarValue: progressBarValue,
            pushToNavigationStack: pushToNavigationStack,
            setProgressBarValue: setProgressBarValue,
        } }, props.children));
};
export default NavigationManagerProvider;
