import { getLocalizedCurrency } from "../helpers/getLocalizedCurrency";
import i18n from "../i18n/i18n";
import { useMemo } from "react";
export var useGetCurrency = function (countryIsoCode) {
    return useMemo(function () {
        if (countryIsoCode) {
            getLocalizedCurrency(i18n.language, countryIsoCode);
        }
        return getLocalizedCurrency(i18n.language, countryIsoCode);
    }, [i18n.language, countryIsoCode]);
};
