// store id url
export var storeIdURL = function (portal_type) {
    return "/api/" + portal_type + "/store-id";
};
// store info url
export var storeURL = function (portal_type, store_id) { return "/api/" + portal_type + "/store/" + store_id; };
// promotions url
export var promotionsURL = function (store_id) {
    return "/api/web-portal/store/" + store_id + "/promotion";
};
// all promotions url
export var allPromotionsURL = "/api/web-portal/customer/promotions";
//  cities url
export var citiesURL = "/api/web-portal/store-city";
// districts url
export var districtsURL = "/api/web-portal/store-district";
// sub districts url
export var subDistrictsURL = "/api/web-portal/store-sub-district";
// pickup branches url
export var pickupBranchesURL = function (store_id) {
    return "/api/web-portal/pickup/branches/" + store_id;
};
// menus url
export var menuURL = "/api/web-portal/store/menu";
// branch info url
export var branchInfoURL = "api/web-portal/branch";
// portal clicks
export var portalClicksURL = function (store_id) {
    return "api/web-portal/store/portal-clicks/" + store_id;
};
// delivery branches
export var deliveryBranchesURL = "api/web-portal/delivery/branches";
// user type
export var userTypeURL = "/api/web-portal/customer/user-type";
// OTP
export var generateOtpURL = "/api/web-portal/customer/generate-otp";
// OTP verification
export var OTPVerificationURL = "/api/web-portal/customer/verify-otp";
// user info
export var userInfoURL = "/api/web-portal/customer/user-info";
// gift list
export var giftsURL = function (store_id) {
    return "/api/web-portal/customer/gift-list/" + store_id;
};
// CUSTOMER DELIVERY ADDRESS
// get
export var getCustomerDeliveryAddressURL = "/api/web-portal/customer/last-delivery-address";
// update
export var updateCustomerDeliveryAddressURL = function (userAddressId) {
    return "/api/web-portal/customer/delivery-address/" + userAddressId;
};
// add
export var addCustomerDeliveryAddressURL = "/api/web-portal/customer/delivery-address";
// validatate customer promotion
export var validateCustomerPromotionURL = "/api/web-portal/customer/validate-promotion";
// points
export var getPointsURL = "/api/web-portal/customer/order-points";
// order statuses
export var orderStatusesURL = "/api/web-portal/customer/order-status";
// create delivery order
export var createDeliveryOrderURL = "/api/web-portal/customer/order/delivery";
// create pickup order
export var createPickupOrderURL = "/api/web-portal/customer/order/pickup";
// cancel order
export var cancelOrderURL = "/api/web-portal/customer/cancel-order";
// basket orders url
export var basketOrderURL = function (branch_id) {
    return "/api/web-portal/orders/basket-order/" + branch_id;
};
// order detail
export var currentOrderDetailsURL = "/api/web-portal/customer/current-order-details";
export var getPaymentCardsURL = function (storeId) {
    return "/api/v2/web-portal/customer/online-payment/cards/" + storeId;
};
export var getCardRegisterationData = "/api/web-portal/customer/online-payment/amazon/card-registeration-data";
// ordering QR URLs
export var createOrderInBranch = "/api/qr-portal/customer/order/in-branch";
export var qrPortalBranchStatusURL = "/api/qr-portal/branch-status";
