import { action, payload } from "ts-action";
import { getUserTypeAction, getUserInfoAction, generateOtpAction, addUserInfoAction, verifyOTPAction, addCustomerDeliveryAddressAction, getCustomerDeliveryAddressAction, updateCustomerDeliveryAddressAction, updateUserInfoAction, } from "../constants";
// get user info actions
export var getUserInfo = action(getUserInfoAction.requested, payload());
export var getUserInfoSuccess = action(getUserInfoAction.fulfilled, payload());
export var getUserInfoFailure = action(getUserInfoAction.rejected, payload());
// get customer type actions
export var getUserType = action(getUserTypeAction.requested);
export var getUserTypeSuccess = action(getUserTypeAction.fulfilled, payload());
export var getUserTypeFailure = action(getUserTypeAction.rejected, payload());
// generate OTP actions
export var generateOTP = action(generateOtpAction.requested, payload());
export var generateOTPSuccess = action(generateOtpAction.fulfilled, payload());
export var generateOTPFailure = action(generateOtpAction.rejected, payload());
// edit user info actions
export var updateUserInfo = action(updateUserInfoAction.requested, payload());
export var updateUserInfoSuccess = action(updateUserInfoAction.fulfilled, payload());
export var updateUserInfoFailure = action(updateUserInfoAction.rejected, payload());
// add user info actions
export var addUserInfo = action(addUserInfoAction.requested, payload());
export var addUserInfoSuccess = action(addUserInfoAction.fulfilled, payload());
export var addUserInfoFailure = action(addUserInfoAction.rejected, payload());
// verify OTP actions
export var verifyOTP = action(verifyOTPAction.requested, payload());
export var verifyOTPSuccess = action(verifyOTPAction.fulfilled, payload());
export var verifyOTPFailure = action(verifyOTPAction.rejected, payload());
// get customer delivery address
export var getCustomerDeliveryAddress = action(getCustomerDeliveryAddressAction.requested, payload());
export var getCustomerDeliveryAddressSuccess = action(getCustomerDeliveryAddressAction.fulfilled, payload());
export var getCustomerDeliveryAddressFailure = action(getCustomerDeliveryAddressAction.rejected, payload());
// add customer delivery address
export var addCustomerDeliveryAddress = action(addCustomerDeliveryAddressAction.requested, payload());
export var addCustomerDeliveryAddressSuccess = action(addCustomerDeliveryAddressAction.fulfilled, payload());
export var addCustomerDeliveryAddressFailure = action(addCustomerDeliveryAddressAction.rejected, payload());
// update customer delivery address
export var updateCustomerDeliveryAddress = action(updateCustomerDeliveryAddressAction.requested, payload());
export var updateCustomerDeliveryAddressSuccess = action(updateCustomerDeliveryAddressAction.fulfilled, payload());
export var updateCustomerDeliveryAddressFailure = action(updateCustomerDeliveryAddressAction.rejected, payload());
