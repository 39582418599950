var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback } from "react";
import i18n from "../i18n/i18n";
export var useTranslation = function (ns) {
    var t = useCallback(function (key, options) {
        return i18n.t(key, __assign({ ns: ns }, options));
    }, [i18n]);
    return {
        t: t,
        i18n: i18n,
    };
};
