var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getDistrictsSuccess } from "../actions";
import { convertToIds } from "./lib";
import { getDistrictsAction } from "../constants";
import { searchForDistrict } from "../actions/zones-actions";
var initialState = {
    districts: [],
    districtById: {},
    shownDistricts: [],
    selectedCity: "",
};
export var getDistrictsReducer = withLoadingReducer(reducer(initialState, on(getDistrictsSuccess, function (state, _a) {
    var _b = _a.payload, districts = _b.districts, city_id = _b.city_id;
    return (__assign(__assign({}, state), { districts: __spread(new Set(__spread(convertToIds(districts.sort(function (a, b) {
            return (a.ArName || a.EnName).localeCompare(b.ArName || a.EnName);
        }))))), districtById: districts.reduce(function (acc, area) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[area.id] = area, _a)));
        }, {}), shownDistricts: __spread(new Set(__spread(convertToIds(districts.sort(function (a, b) {
            return (a.ArName || a.EnName).localeCompare(b.ArName || b.EnName);
        }))))), selectedCity: city_id }));
}), on(searchForDistrict, function (state, action) {
    var districtById = state.districtById, districts = state.districts;
    var term = action.payload.trim().toLowerCase();
    if (!term) {
        return __assign(__assign({}, state), { shownDistricts: districts });
    }
    var searchedDistricts = districts.filter(function (c) {
        var district = districtById[c];
        var formattedEnDistrictName = district.EnName.toLowerCase();
        var enNameMatches = formattedEnDistrictName.includes(term);
        var arNameMatches = district.ArName.includes(term);
        return enNameMatches || arNameMatches;
    });
    return __assign(__assign({}, state), { shownDistricts: searchedDistricts });
})), getDistrictsAction);
