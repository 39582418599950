// generic action template
export var genericAction = function (action) { return ({
    fulfilled: action + "_FULFILLED",
    rejected: action + "_REJECTED",
    requested: action + "_REQUESTED",
}); };
// get store id action
var ACTION_GET_STORE_ID_AND_STATUS = "ACTION_GET_STORE_ID_AND_STATUS";
export var getStoreIdAndStatusAction = genericAction(ACTION_GET_STORE_ID_AND_STATUS);
// get store action
var ACTION_GET_STORE_INFO = "ACTION_GET_STORE_INFO";
export var getStoreInfoAction = genericAction(ACTION_GET_STORE_INFO);
// get store promotions action
var ACTION_GET_STORE_PROMOTIONS = "ACTION_GET_STORE_PROMOTIONS";
export var getStorePromotionsAction = genericAction(ACTION_GET_STORE_PROMOTIONS);
// get cities action
var ACTION_GET_CITIES = "ACTION_GET_CITIES";
export var getCitiesAction = genericAction(ACTION_GET_CITIES);
// get districts action
var ACTION_GET_DISTRICTS = "ACTION_GET_DISTRICTS";
export var getDistrictsAction = genericAction(ACTION_GET_DISTRICTS);
// git subdistricts action
var ACTION_GET_SUB_DISTRICTS = "ACTION_GET_SUB_DISTRICTS";
export var getSubDistrictsAction = genericAction(ACTION_GET_SUB_DISTRICTS);
// git pickup branches action
var ACTION_GET_PICKUP_BRANCHES = "ACTION_GET_PICKUP_BRANCHES";
export var getPickupBranchesAction = genericAction(ACTION_GET_PICKUP_BRANCHES);
//get menu action
var ACTION_GET_MENU = "ACTION_GET_MENU";
export var getMenuAction = genericAction(ACTION_GET_MENU);
// get branch info
var ACTION_GET_BRANCH_INFO = "ACTION_GET_BRANCH_INFO";
export var getBranchInfoAction = genericAction(ACTION_GET_BRANCH_INFO);
// search actions
export var ACTION_SEARCH_FOR_CITY = "ACTION_SEARCH_FOR_CITY";
export var ACTION_SEARCH_FOR_DISTRICT = "ACTION_SEARCH_FOR_DISTRICT";
export var ACTION_SEARCH_FOR_SUBDISTRICT = "ACTION_SEARCH_FOR_SUBDISTRICT";
export var ACTION_SEARCH_FOR_BRANCH = "ACTION_SEARCH_FOR_BRANCH";
export var ACTION_SEARCH_FOR_MENU_ITEM = "ACTION_SEARCH_FOR_MENU_ITEM";
// portal clicks action
var ACTION_PORTAL_CLICKS = "ACTION_PORTAL_CLICKS";
export var portalClicksAction = genericAction(ACTION_PORTAL_CLICKS);
// get user info action
var ACTION_GET_USER_INFO = "ACTION_GET_USER_INFO";
export var getUserInfoAction = genericAction(ACTION_GET_USER_INFO);
// get customer type
var ACTION_GET_USER_TYPE = "ACTION_GET_USER_TYPE";
export var getUserTypeAction = genericAction(ACTION_GET_USER_TYPE);
// generate OTP
var ACTION_GENERATE_OTP = "ACTION_GENERATE_OTP";
export var generateOtpAction = genericAction(ACTION_GENERATE_OTP);
// edit user info action
var ACTION_UPDATE_USER_INFO = "ACTION_UPDATE_USER_INFO";
export var updateUserInfoAction = genericAction(ACTION_UPDATE_USER_INFO);
// add user info action
var ACTION_ADD_USER_INFO = "ACTION_ADD_USER_INFO";
export var addUserInfoAction = genericAction(ACTION_ADD_USER_INFO);
// verify OTP
var ACTION_VERIFY_OTP = "ACTION_VERIFY_OTP";
export var verifyOTPAction = genericAction(ACTION_VERIFY_OTP);
// get gift list action
var ACTION_GET_GIFTS = "ACTION_GET_GIFTS";
export var getGiftsAction = genericAction(ACTION_GET_GIFTS);
// points
var ACTION_GET_POINTS = "ACTION_GET_POINTS";
export var getPointsAction = genericAction(ACTION_GET_POINTS);
// get all promotions action
var ACTION_GET_CUSTOMER_PROMOTIONS = "ACTION_GET_CUSTOMER_PROMOTIONS";
export var getCustomerPromotionsAction = genericAction(ACTION_GET_CUSTOMER_PROMOTIONS);
// customer delivery address actions
// -> get
var ACTION_GET_CUSTOMER_DELIVERY_ADDRESS = "ACTION_GET_CUSTOMER_DELIVERY_ADDRESS";
export var getCustomerDeliveryAddressAction = genericAction(ACTION_GET_CUSTOMER_DELIVERY_ADDRESS);
// -> add
var ACTION_ADD_CUSTOMER_DELIVERY_ADDRESS = "ACTION_ADD_CUSTOMER_DELIVERY_ADDRESS";
export var addCustomerDeliveryAddressAction = genericAction(ACTION_ADD_CUSTOMER_DELIVERY_ADDRESS);
// -> update
var ACTION_UPDATE_CUSTOMER_DELIVERY_ADDRESS = "ACTION_UPDATE_CUSTOMER_DELIVERY_ADDRESS";
export var updateCustomerDeliveryAddressAction = genericAction(ACTION_UPDATE_CUSTOMER_DELIVERY_ADDRESS);
// get order statuses
var ACTION_GET_ORDER_STATUSES = "ACTION_GET_ORDER_STATUSES";
export var getOrderStatusesAction = genericAction(ACTION_GET_ORDER_STATUSES);
// clear prev order status
export var clearPrevOrderAction = "CLEAR_PREV_ORDER_ACTION";
// validate customer promotion
var ACTION_VALIDATE_CUSTOMER_PROMOTION = "ACTION_VALIDATE_CUSTOMER_PROMOTION";
export var validateCustomerPromotionAction = genericAction(ACTION_VALIDATE_CUSTOMER_PROMOTION);
// create delivery order
var ACTION_CREATE_DELIVERY_ORDER = "ACTION_CREATE_DELIVERY_ORDER";
export var createDeliveryOrderAction = genericAction(ACTION_CREATE_DELIVERY_ORDER);
// create pickup order
var ACTION_CREATE_PICKUP_ORDER = "ACTION_CREATE_PICKUP_ORDER";
export var createPickupOrderAction = genericAction(ACTION_CREATE_PICKUP_ORDER);
// cancel order
var ACTION_CANCEL_ORDER = "ACTION_CANCEL_ORDER";
export var cancelOrderAction = genericAction(ACTION_CANCEL_ORDER);
// count basket orders
var ACTION_COUNT_BASKET_ORDER = "ACTION_COUNT_BASKET_ORDER";
export var countBasketOrderAction = genericAction(ACTION_COUNT_BASKET_ORDER);
// order details
var ACTION_GET_ORDER_DETAILS = "ACTION_GET_ORDER_DETAILS";
export var getOrderDetailsAction = genericAction(ACTION_GET_ORDER_DETAILS);
// loader
export var ACTION_LOADING = genericAction("ACTION_LOADING");
// Ordering QR 
var ACTION_GET_QR_BRANCH_STATUS = "ACTION_GET_QR_BRANCH_STATUS";
export var getQRBranchStatusAction = genericAction(ACTION_GET_QR_BRANCH_STATUS);
