var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { addCustomerDeliveryAddress, addCustomerDeliveryAddressFailure, updateUserInfoFailure, } from "./../actions/user-actions";
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getCustomerDeliveryAddressAction } from "../constants";
import { getCustomerDeliveryAddressSuccess, updateCustomerDeliveryAddress, } from "../actions";
var initialState = {
    customerDeliveryAddresses: {
        id: "",
        company_name: "",
        apartment: "",
        building: "",
        floor: "",
        street: "",
        more_info: "",
        type: "",
        sub_district: {
            ArName: "",
            EnName: "",
            city: {
                ArName: "",
                EnName: "",
                created_at: 0,
                id: "",
                updated_at: 0,
            },
            country: {
                ArName: "",
                EnName: "",
                id: "",
                ISO_code: "",
                country_code: "",
                created_at: 0,
                updated_at: 0,
            },
            created_at: 0,
            district: {
                ArName: "",
                EnName: "",
                created_at: 0,
                id: "",
                updated_at: 0,
            },
            id: "",
            updated_at: 0,
        },
    },
};
export var getCustomerDeliveryAddressReducer = withLoadingReducer(reducer(initialState, on(getCustomerDeliveryAddressSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { customerDeliveryAddresses: payload }));
}), on(updateCustomerDeliveryAddress, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { customerDeliveryAddresses: __assign(__assign({}, state.customerDeliveryAddresses), payload) }));
}), on(updateUserInfoFailure, function (state) { return (__assign(__assign({}, state), { customerDeliveryAddresses: __assign({}, state.customerDeliveryAddresses) })); }), on(addCustomerDeliveryAddress, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { customerDeliveryAddresses: __assign(__assign({}, state.customerDeliveryAddresses), payload) }));
}), on(addCustomerDeliveryAddressFailure, function (state) { return (__assign(__assign({}, state), { customerDeliveryAddresses: __assign({}, state.customerDeliveryAddresses) })); })), getCustomerDeliveryAddressAction);
