export default {
    giftsList: "قائمة الهدايا",
    knowMore: "لمعرفة المزيد",
    giftsListNote: ".عن طريق كوينز {{store_name}} الهدايا تقدم من مطعم",
    addToOrder: "أضف هدايا إلى طلبك",
    singleGift: "هدية",
    multipleGifts: "هدايا",
    tabHere: "اضغط هنا",
    aboutHowToEarn: "",
};
