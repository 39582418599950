export default {
    passcodePlaceholder: "رمز التحقق",
    resend: "إعادة إرسال",
    verify: "تحقق",
    didNotReceiveIt: "رمز التحقق لم يصل؟",
    passcodeTitle: "تم إرسال رسالة نصية إلي",
    fullNameRequired: "رمز التحقق لم يصل؟",
    emailRequired: "البريد الإلكتروني (مطلوب)",
    invalidEmail: "بريد إلكتروني خاطئ",
    fullName: "الاسم بالكامل",
    email: "البريد الالكتروني",
    phoneNumber: "رقم الهاتف",
    next: "التالى",
    sendCode: "ارسل الرمز",
    phoneNumberNote: "سيتم ارسال رساله نصية قصيرة برمز تحقق صالح لمرة واحدة (OTP) إلى هذا الرقم للتحقق منه",
    invalidPhoneNumber: "يجب أن يكون تابعاً للدولة المختارة",
    isRequired: "حقل مطلوب",
};
