var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from "react";
export var useScrollOffset = function () {
    var _a = __read(useState({
        scrollX: 0,
        scrollY: 0,
    }), 2), scrollValue = _a[0], setScrollValue = _a[1];
    useEffect(function () {
        window.addEventListener("scroll", function () {
            var scrollX = window.scrollX, scrollY = window.scrollY;
            setScrollValue({
                scrollX: scrollX,
                scrollY: scrollY,
            });
        });
        return function () {
            return window.removeEventListener("scroll", function () { });
        };
    }, []);
    return scrollValue;
};
