import i18n from "i18next";
import * as LanguageDetector from "i18next-browser-languagedetector";
import en from "./en";
import ar from "./ar";
i18n.use(LanguageDetector).init({
    fallbackLng: "ar",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        ar: ar,
        en: en,
    },
    ns: ["translations"],
    defaultNS: "translations",
});
export default i18n;
export var getCurrentDirection = function () {
    return i18n.language === "ar" ? "rtl" : "ltr";
};
