var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { on, reducer } from "ts-action";
import { clearPrevOrder, getUserInfoSuccess } from "../actions";
import { getUserInfoAction } from "../constants";
import { withLoadingReducer } from "./withLoadingState";
var initialState = {
    country_code: "",
    email: "",
    name: "",
    phone_number: "",
    id: "",
};
export var getUserInfoReducer = withLoadingReducer(reducer(initialState, on(getUserInfoSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), payload));
}), on(clearPrevOrder, function () { return initialState; })), getUserInfoAction);
