/**
 * @description takes dom element and returns element height including border and margin
 *
 * **/
export var getElementHeight = function (element) {
    if (!element)
        return 0;
    return parseInt(element.offsetHeight +
        getComputedStyle(element).marginTop +
        getComputedStyle(element).marginBottom);
};
