export default {
    delivery: "خدمة توصيل",
    pickup: "خدمة استلام",
    viewOrder: "عرض الطلب",
    subtotal: "مبلغ إجمالي",
    required: "حقل مطلوب*",
    chooseOnlyOne: "اختر واحدة فقط",
    comments: "تعليقات",
    commentsPlaceholder: "اضغط لإضافة أي تعليق ...",
    additionalSauce: "Additional Sauce",
    howMany: "كم العدد ؟",
    max: "Max:",
    deleteItem: "حذف العنصر",
    min: "Min:",
    sauce: "Sauce",
    addToOrder: "أضف إلى الطلب",
    vatTitle: "شامل ضريبة القيمة المضافة",
    minimumOrder: "الحد الأدنى للطلب",
    deliveryTime: "وقت التسليم",
    deliveryFees: "رسوم التوصيل",
    preparingTime: "وقت التحضير",
    busy: "المطعم مشغول الآن",
    closed: "المطعم مغلق الآن",
    lessThanMinimum: "الحد الأدنى {{minimum_order_value}}",
    price: "({{price}} {{currency}})",
    taxesIncluded: "شامل الضرائب",
};
