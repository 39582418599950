var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getMenuAction } from "../constants";
import { getMenuSuccess, searchForMenuItem } from "../actions";
import { convertToIds, mapById, mapByPropertyName } from "./lib";
import { isRTLInput } from "../../../helpers";
var initialState = {
    categories: [],
    categoriesById: {},
    menuItemsSearchResults: [],
    menuItemsById: {},
    menuItemsMapByArName: {},
    menuItemsMapByEnName: {},
};
export var menuReducer = withLoadingReducer(reducer(initialState, on(getMenuSuccess, function (state, _a) {
    var payload = _a.payload;
    var payloadCopy = __assign({}, payload);
    var filteredCategories = __spread(payloadCopy.categories).filter(function (category) { return category.enabled === 1; });
    var filteredCategoriesWithEnabledItems = filteredCategories.map(function (category) {
        var filteredItems = category.items.filter(function (catItem) { return catItem.enabled === 1; });
        return __assign(__assign({}, category), { items: filteredItems });
    });
    return __assign(__assign({}, state), { categories: payload
            ? convertToIds(filteredCategoriesWithEnabledItems)
            : [], categoriesById: payload
            ? mapById(filteredCategoriesWithEnabledItems)
            : {}, menuItemsById: payload
            ? filteredCategoriesWithEnabledItems.reduce(function (current, next) { return (__assign(__assign({}, current), mapByPropertyName({
                items: next.items,
                propertyName: "id",
            }))); }, {})
            : {}, menuItemsMapByArName: payload
            ? filteredCategoriesWithEnabledItems.reduce(function (current, next) { return (__assign(__assign({}, current), mapByPropertyName({
                items: next.items,
                propertyName: "ar_name",
            }))); }, {})
            : {}, menuItemsMapByEnName: payload
            ? filteredCategoriesWithEnabledItems.reduce(function (current, next) { return (__assign(__assign({}, current), mapByPropertyName({
                items: next.items,
                propertyName: "en_name",
            }))); }, {})
            : {} });
}), on(searchForMenuItem, function (state, action) {
    var e_1, _a, e_2, _b;
    var searchTerm = action.payload.trim().toLowerCase();
    if (!searchTerm)
        return __assign(__assign({}, state), { menuItemsSearchResults: [] });
    var menuItemsMapByArName = state.menuItemsMapByArName, menuItemsMapByEnName = state.menuItemsMapByEnName;
    var enNameMatches = [];
    var arNameMatches = [];
    var isArabicInput = isRTLInput(searchTerm);
    if (isArabicInput) {
        try {
            for (var _c = __values(Object.entries(menuItemsMapByArName)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), key = _e[0], value = _e[1];
                if (key.toLowerCase().includes(searchTerm))
                    arNameMatches.push(value);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    else {
        try {
            for (var _f = __values(Object.entries(menuItemsMapByEnName)), _g = _f.next(); !_g.done; _g = _f.next()) {
                var _h = __read(_g.value, 2), key = _h[0], value = _h[1];
                if (key.toLowerCase().includes(searchTerm))
                    enNameMatches.push(value);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    var menuItemsSearchResults = isArabicInput
        ? arNameMatches
        : enNameMatches;
    return __assign(__assign({}, state), { menuItemsSearchResults: menuItemsSearchResults });
})), getMenuAction);
