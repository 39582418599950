export default {
    delivery: "خدمة التوصيل",
    menu: "قائمة الطعام",
    choosePreferredBranch: "اختر الفرع الاقرب",
    chooseYourCityAndArea: "اختر مدينتك او منطقتك",
    chooseOrderingMethod: "اختر طريقة الطلب",
    personalInfo: "المعلومات الشخصية",
    phoneNumber: "رقم الهاتف",
    yourOrder: "طلبك",
    phoneNumberVerification: "التحقق من رقم الهاتف",
    searchForCitiesAndAreas: "ابحث عن المدن والمناطق",
    search: "بحث",
    cancel: "إلغاء",
};
