import OrderingStatus from "./OrderingStatus";
import menu from "./menu";
import header from "./header";
import personalInfo from "./personalInfo";
import checkout from "./checkout";
import orderTracking from "./orderTracking";
import addPromocode from "./addPromocode";
import giftsList from "./giftsList";
export default {
    OrderingStatus: OrderingStatus,
    menu: menu,
    header: header,
    personalInfo: personalInfo,
    checkout: checkout,
    orderTracking: orderTracking,
    addPromocode: addPromocode,
    giftsList: giftsList,
};
