export default {
    delivery: "Delivery",
    pickup: "Pickup",
    viewOrder: "View Order",
    subtotal: "Subtotal",
    required: "*Required",
    chooseOnlyOne: "Choose only one",
    comments: "Comments",
    commentsPlaceholder: "Tab to add any comment…",
    additionalSauce: "Additional Sauce",
    howMany: "How many ?",
    max: "Max:",
    deleteItem: "Delete Item",
    sauce: "Sauce",
    min: "Min:",
    addToOrder: "Add to order",
    vatTitle: "VAT included",
    taxesIncluded: "Taxes Included",
    minimumOrder: "Minimum order",
    deliveryTime: "min Delivery time",
    deliveryFees: "Delivery fees",
    preparingTime: "min preparing time",
    busy: "Restaurant is busy now",
    closed: "Restaurant is now closed",
    lessThanMinimum: "Min: {{minimum_order_value}}",
    price: "({{price}} {{currency}})",
};
