import { atom } from "recoil";
export var initModifiersGroupsAtom = function () { return ({
    drawerIsOpen: false,
    currentSelectIndex: "",
    selectedItemModifiersGroups: [],
    currentSelectedMenuItem: {
        ar_description: "",
        ar_name: "",
        created_at: 0,
        en_description: "",
        en_name: "",
        enabled: 0,
        id: "",
        image: "",
        large_image: "",
        modifiers_groups: [],
        order: 0,
        price: 0,
        updated_at: 0,
    },
}); };
export var modifiersGroupsAtom = atom({
    key: "modifiersGroupsState",
    default: initModifiersGroupsAtom(),
});
export var inAppSearch = atom({
    key: "searchIsActive",
    default: false,
});
export var initCheckoutAtom = function () { return ({
    checkoutItemsMap: {},
    currency: "",
    deliveryFees: 0,
    subTotal: 0,
    total: 0,
    promo_code: "",
    comment: "",
    gifts: [],
    orderType: "",
    payment_method: "cash",
    noOfItems: 0,
    basket_id: undefined,
    in_branch_order_type: undefined,
    table_number: undefined,
    dirty: false,
}); };
export var checkoutAtom = atom({
    key: "checkoutAtom",
    default: initCheckoutAtom(),
});
export var zonesAtom = atom({
    key: "zonesAtom",
    default: {
        dirty: false,
        city_selected: {
            ArName: undefined,
            EnName: undefined,
            id: "",
        },
        area_selected: {
            ArName: undefined,
            EnName: undefined,
            id: "",
        },
    },
});
export var customerDeliveryAddressAtom = atom({
    key: "customerDeliveryAddressAtom",
    default: {
        type: "home",
        street: "",
        floor: "",
        apartment: "",
        more_info: "",
        sub_district_id: "",
        building: "",
        company_name: "",
    },
});
export var personalInfoAtom = atom({
    key: "personalInfoAtom",
    default: {
        phone_number: "",
        country_code: "",
        name: "",
        email: "",
    },
});
export var oneTimePasscodeAtom = atom({
    key: "oneTimePasscodeAtom",
    default: "",
});
export var subdistrictIdAtom = atom({
    key: "subdistrictIdAtom",
    default: "",
});
export var initSelectedPromocodeAtom = function () { return ({
    ar_desc: "",
    ar_title: "",
    created_at: 0,
    customers_file_path: "",
    desc: "",
    discount_value: 0,
    end_time: 0,
    id: "",
    max_discount_value: 0,
    max_usage_times: 0,
    min_threshold: 0,
    multiplier_value: 0,
    ordering_type: "",
    promo_category: "",
    promo_code: "",
    start_time: 0,
    title: "",
    updated_at: 0,
    user_id: "",
    targeting_method: "",
    dirty: false,
}); };
export var selectedPromocode = atom({
    key: "selectedPromocode",
    default: initSelectedPromocodeAtom(),
});
// toast atom
var initToastAtom = function () { return ({
    displayTime: 5,
    isOpen: false,
    message: "Default message!",
    type: "info",
}); };
export var toastAtom = atom({
    key: "toast",
    default: initToastAtom(),
});
export var qrPortalAtom = atom({
    key: "qrPortalAtom",
    default: {
        is_qr_portal: false,
        in_branch_order_type: "",
    },
});
