var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { OrderingPortalStatus } from "../../../types";
import { getStoreIdAndStatusAction } from "../constants";
import { getStoreIdAndStatusSuccess } from "../actions/store-actions";
export var storeIdReducer = withLoadingReducer(reducer({
    store_id: "",
    web_portal_status: OrderingPortalStatus.publish,
    preview_link: "",
}, on(getStoreIdAndStatusSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), payload));
})), getStoreIdAndStatusAction);
