var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getPickupBranchesAction } from "../constants";
import { getPickupBranchesSuccess } from "../actions";
import { convertToIds, mapById } from "./lib";
import { searchForBranch } from "../actions/zones-actions";
var initialState = {
    branches: [],
    branchesById: {},
    shownBranches: [],
};
export var getPickupBranchesReducer = withLoadingReducer(reducer(initialState, on(getPickupBranchesSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { branches: convertToIds(payload.sort(function (a, b) { return a.name.localeCompare(b.name); })), branchesById: mapById(payload), shownBranches: convertToIds(payload.sort(function (a, b) { return a.name.localeCompare(b.name); })) }));
}), on(searchForBranch, function (state, action) {
    var branches = state.branches, branchesById = state.branchesById;
    var term = action.payload.trim().toLowerCase();
    if (!term) {
        return __assign(__assign({}, state), { shownBranches: branches });
    }
    var searchedBranches = branches.filter(function (c) {
        var branch = branchesById[c];
        var formattedBranchName = branch.name.toLowerCase();
        var nameMatches = formattedBranchName.includes(term);
        var addressMatches = branch.address.toLowerCase().includes(term);
        return nameMatches || addressMatches;
    });
    return __assign(__assign({}, state), { shownBranches: searchedBranches });
})), getPickupBranchesAction);
