export var amplitude_eventTypes = {
    addPersonalData: "Added presonal data",
    verifiedPhonenumber: "Verified phone number",
    resentOTPMessage: "Resent OTP message",
    visitedOTPPage: "Visited OTP page",
    selectedDeliveryZone: "Selected delivery zone",
    selectedPickupBranch: "Selected a pickup branch",
    visitedLandingPage: "Visited landing page",
    completedOnlineOrder: "Completed online order",
    checkedOut: "Checked Out",
    viewedCheckout: "Viewed checkout",
    viewedBasket: "Viewed basket",
    addedMenuItem: "Added menu item",
    viewedMenuItem: "Viewed menu item",
    viewedOrderingMenu: "Viewed Ordering Menu",
};
