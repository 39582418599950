import { action, payload } from "ts-action";
import { getCitiesAction, getDistrictsAction, getSubDistrictsAction, getPickupBranchesAction, ACTION_SEARCH_FOR_DISTRICT, ACTION_SEARCH_FOR_CITY, ACTION_SEARCH_FOR_SUBDISTRICT, ACTION_SEARCH_FOR_BRANCH, } from "../constants";
//Get Cities Actions
export var getCities = action(getCitiesAction.requested, payload());
export var getCitiesSuccess = action(getCitiesAction.fulfilled, payload());
export var getCitiesFailure = action(getCitiesAction.rejected, payload());
//Get Districts Actions
export var getDistricts = action(getDistrictsAction.requested, payload());
export var getDistrictsSuccess = action(getDistrictsAction.fulfilled, payload());
export var getDistrictsFailure = action(getDistrictsAction.rejected, payload());
//Get Sub Districts Actions
export var getSubDistricts = action(getSubDistrictsAction.requested, payload());
export var getSubDistrictsSuccess = action(getSubDistrictsAction.fulfilled, payload());
export var getSubDistrictsFailure = action(getSubDistrictsAction.rejected, payload());
// Pickup Branches
export var getPickupBranches = action(getPickupBranchesAction.requested, payload());
export var getPickupBranchesSuccess = action(getPickupBranchesAction.fulfilled, payload());
export var getPickupBranchesFailure = action(getPickupBranchesAction.rejected, payload());
// search
export var searchForCity = action(ACTION_SEARCH_FOR_CITY, payload());
export var searchForDistrict = action(ACTION_SEARCH_FOR_DISTRICT, payload());
export var searchForSubdistrict = action(ACTION_SEARCH_FOR_SUBDISTRICT, payload());
export var searchForBranch = action(ACTION_SEARCH_FOR_BRANCH, payload());
