import { action, payload } from "ts-action";
import { getStoreInfoAction, getStoreIdAndStatusAction } from "../constants";
// get store id actions
export var getStoreIdAndStatus = action(getStoreIdAndStatusAction.requested, payload());
export var getStoreIdAndStatusSuccess = action(getStoreIdAndStatusAction.fulfilled, payload());
export var getStoreIdAndStatusFail = action(getStoreIdAndStatusAction.rejected, payload());
// get store actions
export var getStoreInfo = action(getStoreInfoAction.requested, payload());
export var getStoreInfoSuccess = action(getStoreInfoAction.fulfilled, payload());
export var getStoreInfoFail = action(getStoreInfoAction.rejected, payload());
