var _a;
export var languageCodesForArabic = (_a = {},
    _a["aao"] = "aao",
    _a["abh"] = "abh",
    _a["abv"] = "abv",
    _a["acm"] = "acm",
    _a["acq"] = "acq",
    _a["acw"] = "acw",
    _a["acx"] = "acx",
    _a["acy"] = "acy",
    _a["adf"] = "adf",
    _a["aeb"] = "aeb",
    _a["aec"] = "aec",
    _a["afb"] = "afb",
    _a["ajp"] = "ajp",
    _a["apc"] = "apc",
    _a["ar"] = "ar",
    _a["ssh"] = "shh",
    _a["shu"] = "shu",
    _a["pga"] = "pga",
    _a["ayp"] = "ayp",
    _a["ayn"] = "ayn",
    _a["ayl"] = "ayl",
    _a["ayh"] = "ayh",
    _a["avl"] = "avl",
    _a["auz"] = "auz",
    _a["arz"] = "arz",
    _a["ary"] = "ary",
    _a["ars"] = "ars",
    _a["arq"] = "arq",
    _a["arb"] = "arb",
    _a["apd"] = "apd",
    _a["ar-EG"] = "ar-EG",
    _a["ar-SA"] = "ar-SA",
    _a["ar-AE"] = "ar-AE",
    _a["ar-OM"] = "ar-OM",
    _a["ar-BH"] = "ar-BH",
    _a);
