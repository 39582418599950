var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var LoadingStatus;
(function (LoadingStatus) {
    LoadingStatus["success"] = "success";
    LoadingStatus["failed"] = "failed";
    LoadingStatus["loading"] = "loading";
})(LoadingStatus || (LoadingStatus = {}));
export var withLoadingReducer = function (reducer, genericLoadingAction) {
    var _a;
    var loadingStateMap = (_a = {},
        _a[genericLoadingAction.requested] = LoadingStatus.loading,
        _a[genericLoadingAction.fulfilled] = LoadingStatus.success,
        _a[genericLoadingAction.rejected] = LoadingStatus.failed,
        _a);
    return function (state, action) {
        if (state === void 0) { state = __assign(__assign({}, reducer(undefined, { type: "" })), { loadingStatus: undefined }); }
        if (action === void 0) { action = { type: "" }; }
        var rawState = reducer(state, action);
        return __assign(__assign({}, rawState), { loadingStatus: loadingStateMap[action.type] || state.loadingStatus });
    };
};
