import * as React from "react";
import i18n from "../i18n/i18n";
export var getRtlClass = function (stylesModule) {
    var isRtl = i18n.language === "ar";
    var rtlClass = stylesModule.rtl;
    var hasRtlClass = !!stylesModule.rtl;
    var shouldReturnRtlClass = isRtl && hasRtlClass;
    return shouldReturnRtlClass ? rtlClass : "";
};
export var useRtlClass = function (stylesModule) {
    if (stylesModule === void 0) { stylesModule = { rtl: "rtl" }; }
    return React.useMemo(function () { return getRtlClass(stylesModule); }, [i18n.language]);
};
