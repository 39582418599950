var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { getSubDistrictsSuccess } from "../actions";
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getSubDistrictsAction } from "../constants";
import { convertToIds, mapById } from "./lib";
import { searchForSubdistrict } from "../actions/zones-actions";
var initialState = {
    subdistricts: [],
    subdistrictById: {},
    shownSubdistricts: [],
    selectedDistrict: "",
};
export var getSubdistrictReducer = withLoadingReducer(reducer(initialState, on(getSubDistrictsSuccess, function (state, _a) {
    var _b = _a.payload, subdistricts = _b.subdistricts, selectedDistrict = _b.selectedDistrict;
    return (__assign(__assign({}, state), { subdistricts: __spread(new Set(__spread(convertToIds(subdistricts.sort(function (a, b) {
            return (a.ArName || a.EnName).localeCompare(b.ArName || b.EnName);
        }))))), subdistrictById: mapById(subdistricts), shownSubdistricts: __spread(new Set(__spread(convertToIds(subdistricts.sort(function (a, b) {
            return (a.ArName || a.EnName).localeCompare(b.ArName || b.EnName);
        }))))), selectedDistrict: selectedDistrict }));
}), on(searchForSubdistrict, function (state, action) {
    var subdistrictById = state.subdistrictById, subdistricts = state.subdistricts;
    var term = action.payload.trim().toLowerCase();
    if (!term) {
        return __assign(__assign({}, state), { shownSubdistricts: subdistricts });
    }
    var searchedSubDistricts = subdistricts.filter(function (c) {
        var subdistrict = subdistrictById[c];
        var formattedEnSubdistrictName = subdistrict.EnName.toLowerCase();
        var enNameMatches = formattedEnSubdistrictName.includes(term);
        var arNameMatches = subdistrict.ArName.includes(term);
        return enNameMatches || arNameMatches;
    });
    return __assign(__assign({}, state), { shownSubdistricts: searchedSubDistricts });
})), getSubDistrictsAction);
