import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import form from "redux-form/lib/reducer";
import { storeInfoReducer, getBranchInfoReducer, getCitiesReducer, getPointsReducer, getDistrictsReducer, getPickupBranchesReducer, getSubdistrictReducer, menuReducer, storePromotionsReducer, storeIdReducer, getUserInfoReducer, getUserTypeReducer, verifyOTPReducer, getOrderStatusesReducer, generateOTPReducer, getGiftsReducer, customerPromotionsReducer, validateCustomerPromotionReducer, getCustomerDeliveryAddressReducer, getOrderDetailsReducer, createDeliveryOrderReducer, createPickupOrderReducer, updateUserInfoReducer, cancelOrderReducer, loadingReducer, getQRBranchStatusReducer, } from "./reducers";
import sessionStorage from "redux-persist/es/storage/session";
var persistStoreData = {
    key: "storeDataReducer",
    storage: sessionStorage,
    blacklist: ["loadingStatus"],
};
var persistSelectToken = {
    key: "verifyOTPReducer",
    storage: sessionStorage,
    blacklist: ["loadingStatus"],
};
var persistPersonalInfo = {
    key: "getUserInfoReducer",
    storage: sessionStorage,
    blacklist: ["loadingStatus"],
};
var persistUserType = {
    key: "getUserTypeReducer",
    storage: sessionStorage,
    blacklist: ["loadingStatus"],
};
var qrBranchStatus = {
    key: "getQRBranchStatusReducer",
    storage: sessionStorage,
    blacklist: ["loadingStatus"],
};
var rootReducer = combineReducers({
    form: form,
    storeDataReducer: persistReducer(persistStoreData, storeInfoReducer),
    getBranchInfoReducer: getBranchInfoReducer,
    getCitiesReducer: getCitiesReducer,
    getDistrictsReducer: getDistrictsReducer,
    getPickupBranchesReducer: getPickupBranchesReducer,
    getUserTypeReducer: persistReducer(persistUserType, getUserTypeReducer),
    getOrderDetailsReducer: getOrderDetailsReducer,
    getUserInfoReducer: persistReducer(persistPersonalInfo, getUserInfoReducer),
    getOrderStatusesReducer: getOrderStatusesReducer,
    getSubdistrictReducer: getSubdistrictReducer,
    menuReducer: menuReducer,
    getCustomerDeliveryAddressReducer: getCustomerDeliveryAddressReducer,
    storePromotionsReducer: storePromotionsReducer,
    storeIdReducer: storeIdReducer,
    verifyOTPReducer: persistReducer(persistSelectToken, verifyOTPReducer),
    getPointsReducer: getPointsReducer,
    getGiftsReducer: getGiftsReducer,
    generateOTPReducer: generateOTPReducer,
    customerPromotionsReducer: customerPromotionsReducer,
    validateCustomerPromotionReducer: validateCustomerPromotionReducer,
    createDeliveryOrderReducer: createDeliveryOrderReducer,
    createPickupOrderReducer: createPickupOrderReducer,
    updateUserInfoReducer: updateUserInfoReducer,
    cancelOrderReducer: cancelOrderReducer,
    loadingReducer: loadingReducer,
    getQRBranchStatusReducer: persistReducer(qrBranchStatus, getQRBranchStatusReducer),
});
export default rootReducer;
