var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { on, reducer } from "ts-action";
import { PromocodeValidationScheme, } from "../../../types";
import { validateCustomerPromotionFail, validateCustomerPromotionSuccess, } from "../actions";
import { validateCustomerPromotionAction } from "../constants";
import { withLoadingReducer } from "./withLoadingState";
var initialState = {
    desc: "",
    discount_value: 0,
    end_time: 0,
    max_discount_value: 0,
    max_usage_times: 0,
    min_threshold: 0,
    ordering_type: "",
    promo_category: "",
    promo_code: "",
    start_time: 0,
    title: "",
    type: undefined,
    message: "",
    id: "",
    ar_desc: "",
    ar_title: "",
    created_at: 0,
    customers_file_path: "",
    multiplier_value: 0,
    updated_at: 0,
    user_id: "",
};
export var validateCustomerPromotionReducer = withLoadingReducer(reducer(initialState, on(validateCustomerPromotionSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), payload));
}), on(validateCustomerPromotionFail, function (state, _a) {
    var payload = _a.payload;
    var _b, _c;
    return (__assign(__assign({}, state), { message: ((_c = (_b = payload) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.data) === PromocodeValidationScheme.notFound
            ? "Invalid Promocode"
            : payload.response.data }));
})), validateCustomerPromotionAction);
