var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { on, reducer } from "ts-action";
import { clearPrevOrder, getOrderDetailsSuccess, getOrderDetailsFailure, } from "../actions";
import { getOrderDetailsAction } from "../constants";
import { withLoadingReducer } from "./withLoadingState";
var initialState = {
    api_status: 204,
    order: {
        created_at: "",
        email: "",
        user_id: "",
        user_name: "",
        delivery_fees: 0,
        branch: {
            address: "",
            id: "",
            lat: 0,
            lng: 0,
            pickup_prep_max: 0,
            pickup_prep_min: 0,
        },
        address: {
            apartment: "",
            building: "",
            created_at: 0,
            floor: "",
            id: "",
            more_info: "",
            street: "",
            sub_district: {
                ArName: "",
                EnName: "",
                city: {
                    ArName: "",
                    EnName: "",
                    created_at: 0,
                    id: "",
                    updated_at: 0,
                },
                created_at: 0,
                district: {
                    ArName: "",
                    EnName: "",
                    created_at: 0,
                    id: "",
                    updated_at: 0,
                },
                id: "",
                updated_at: 0,
            },
            type: "",
            updated_at: 0,
        },
        id: "",
        items: [],
        order_code: "",
        order_type: "",
        payment_method: "",
        status: "",
        store: {
            activation_date: 0,
            active_leaderboard: 0,
            allow_leaderboard: 0,
            allow_reach: 0,
            api_key: "",
            background_color: "",
            bio: "",
            converting_ratio: 0,
            country_code: "",
            country_iso_code: "",
            created_at: 0,
            id: "",
            manager_id: "",
            name: "",
            payment_date: 0,
            payment_notification: 0,
            payment_status: 0,
            payment_term: 0,
            pos: "",
            status: 0,
            store_image: "",
            timezone: "",
            translated_name: "",
            updated_at: 0,
            branch: {
                address: "",
                id: "",
                lat: 0,
                lng: 0,
                pickup_prep_max: 0,
                pickup_prep_min: 0,
            },
        },
        total_invoices: 0,
        was_scheduled: 0,
        estimated_delivery_time: "",
        estimated_ready_time: "",
        scheduled_at: 0,
        total_invoices_without_promo_code: "",
    },
};
export var getOrderDetailsReducer = withLoadingReducer(reducer(initialState, on(clearPrevOrder, function (state, _a) { return (__assign(__assign({}, state), { order: {
        created_at: "",
        email: "",
        user_id: "",
        user_name: "",
        delivery_fees: 0,
        branch: {
            address: "",
            id: "",
            lat: 0,
            lng: 0,
            pickup_prep_max: 0,
            pickup_prep_min: 0,
        },
        address: {
            apartment: "",
            building: "",
            created_at: 0,
            floor: "",
            id: "",
            more_info: "",
            street: "",
            sub_district: {
                ArName: "",
                EnName: "",
                city: {
                    ArName: "",
                    EnName: "",
                    created_at: 0,
                    id: "",
                    updated_at: 0,
                },
                created_at: 0,
                district: {
                    ArName: "",
                    EnName: "",
                    created_at: 0,
                    id: "",
                    updated_at: 0,
                },
                id: "",
                updated_at: 0,
            },
            type: "",
            updated_at: 0,
        },
        id: "",
        items: [],
        order_code: "",
        order_type: "",
        payment_method: "",
        status: "",
        store: {
            activation_date: 0,
            active_leaderboard: 0,
            allow_leaderboard: 0,
            allow_reach: 0,
            api_key: "",
            background_color: "",
            bio: "",
            converting_ratio: 0,
            country_code: "",
            country_iso_code: "",
            created_at: 0,
            id: "",
            manager_id: "",
            name: "",
            payment_date: 0,
            payment_notification: 0,
            payment_status: 0,
            payment_term: 0,
            pos: "",
            status: 0,
            store_image: "",
            timezone: "",
            translated_name: "",
            updated_at: 0,
            branch: {
                address: "",
                id: "",
                lat: 0,
                lng: 0,
                pickup_prep_max: 0,
                pickup_prep_min: 0,
            },
        },
        total_invoices: 0,
        was_scheduled: 0,
        estimated_delivery_time: "",
        estimated_ready_time: "",
        scheduled_at: 0,
        total_invoices_without_promo_code: "",
    } })); }), on(getOrderDetailsSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { order: payload.order, api_status: payload.order.id ? 200 : 204 }));
}), on(getOrderDetailsFailure, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { api_state: payload }));
})), getOrderDetailsAction);
