export default {
    passcodePlaceholder: "One time password (OTP)",
    resend: "Resend",
    verify: "Verify",
    didNotReceiveIt: "Didn’t receive it?",
    passcodeTitle: "We’ve sent a SMS to:",
    fullNameRequired: "Full name required",
    emailRequired: "Email required",
    invalidEmail: "Invalid email",
    fullName: "Full Name",
    email: "E-mail address",
    phoneNumber: "Phone number",
    next: "Next",
    sendCode: "Send Code",
    phoneNumberNote: "We’ll send a SMS with One Time Password (OTP) to this phone number to verify it.",
    isRequired: "Required",
    invalidPhoneNumber: "Must match the selected country code",
};
