var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from "react";
export var usePreviewId = function () {
    var _a = __read(useState(""), 2), previewId = _a[0], setPreviewId = _a[1];
    useEffect(function () {
        if (!window)
            setPreviewId("");
        var baseURLSplitted = window.location.href.split("/");
        var preview_id = baseURLSplitted[baseURLSplitted.indexOf("#") + 1];
        if (!previewId)
            setPreviewId(preview_id);
    }, [window]);
    return previewId;
};
