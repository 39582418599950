export default {
    delivery: "Delivery",
    menu: "Menu",
    choosePreferredBranch: "Choose preferred branch",
    chooseYourCityAndArea: "Choose your city and area",
    chooseOrderingMethod: "Choose ordering method",
    personalInfo: "Personal info",
    phoneNumber: "Phone number",
    yourOrder: "Your order",
    phoneNumberVerification: "Phone number verification",
    searchForCitiesAndAreas: "Search cities and areas",
    search: "Search",
    cancel: "Cancel",
};
