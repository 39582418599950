var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/***
 * @description handles api calls used for setting apps global state
 * and its data
 * ***/
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useRecoilState } from "recoil";
import { useBaseURL, usePreviewId, useRouter, useSelect, useSessionStorage, } from "../../hooks";
import countries from "../../static/countries";
import { checkoutAtom, personalInfoAtom, qrPortalAtom, } from "../../store/recoile-store/atoms";
import { getStoreInfo, getStoreIdAndStatus, portalClicks, getCities, getPickupBranches, getOrderStatuses, getOrderDetails, getUserInfo, loading, getMenu, } from "../../store/redux-store/actions";
import { OrderingPortalStatus, localStatesHashIds, PickupStatus, } from "../../types";
// context
import { NavigationManagerContext } from "./Context/NavigationManagerContext";
// helpers
import { getLocalizedCurrency } from "../../helpers";
import i18n from "../../i18n/i18n";
import { getQRBranchStatus } from "../../store/redux-store/actions/qr-portal-actions";
import { useDeepCompareEffect } from "use-deep-compare";
var NavigationManager = function (Component) {
    var withNavigationManager = function (props) {
        // HANDLING ORDER TRACKING LINKS
        var previewId = usePreviewId();
        // initiations
        var dispatch = useDispatch();
        // recoil
        var _a = __read(useRecoilState(checkoutAtom), 2), checkoutState = _a[0], setCheckoutState = _a[1];
        var _b = __read(useRecoilState(qrPortalAtom), 2), setQRPortalState = _b[1];
        // session storage state
        var _c = __read(useSessionStorage("qr_portal_values", {
            is_qr_portal: false,
            in_branch_order_type: "",
            branch_id: "",
        }), 2), qrPortalValuesInSessionStorage = _c[0], setQRPortalValuesInSessionStorage = _c[1];
        // selectors
        var _d = useSelect(function (state) { return (__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, state.storeDataReducer.store), state.storeIdReducer), state.getUserTypeReducer), state.verifyOTPReducer), state.getSubdistrictReducer), state.getDistrictsReducer), { qr_branch_status: state.getQRBranchStatusReducer })); }), country_iso_code = _d.country_iso_code, web_portal_status = _d.web_portal_status, preview_link = _d.preview_link, store_id = _d.store_id, qr_branch_status = _d.qr_branch_status;
        var baseURL = useBaseURL();
        var history = useHistory();
        var pathname = useLocation().pathname;
        // getting store id effect
        useEffect(function () {
            if (baseURL) {
                // if working on local development server then will use "https://koinz-web-portal.surge.sh/#/EnnabiGrill"
                // as the default store url
                var selectedBaseURL = baseURL.includes("localhost")
                    ? "https://koinz-web-portal.web.app/#/QR_Nola"
                    : baseURL;
                if (baseURL.includes(process.env.KOINZ_WEB_PORTAL)) {
                    dispatch(getStoreIdAndStatus({
                        portal_type: "web-portal",
                        preview_link: selectedBaseURL.trim(),
                    }));
                }
                else if (baseURL.includes(process.env.QR_PORTAL_URL) && previewId) {
                    dispatch(getStoreIdAndStatus({
                        portal_type: "qr-portal",
                        qr_preview_id: previewId,
                        preview_link: selectedBaseURL.trim(),
                    }));
                }
            }
        }, [baseURL, previewId]);
        useEffect(function () {
            if (store_id && baseURL.includes(process.env.KOINZ_WEB_PORTAL)) {
                dispatch(getStoreInfo({
                    store_id: store_id,
                    portal_type: "web-portal"
                }));
                // tracking portal clicks
                dispatch(portalClicks(store_id.trim() ? store_id : qr_branch_status.store_id));
            }
            if ((store_id || qr_branch_status.store_id) && baseURL.includes(process.env.QR_PORTAL_URL)) {
                dispatch(getStoreInfo({
                    store_id: store_id.trim() ? store_id : qr_branch_status.store_id,
                    portal_type: "qr-portal"
                }));
                // tracking portal clicks
                dispatch(portalClicks(store_id.trim() ? store_id : qr_branch_status.store_id));
            }
        }, [store_id, qr_branch_status.store_id, baseURL]);
        // getting cities using iso code returned with store data effect
        useEffect(function () {
            if (web_portal_status === OrderingPortalStatus.unpublish) {
                history.replace("/not-published");
            }
        }, [web_portal_status]);
        var _e = __read(useRecoilState(personalInfoAtom), 2), setPersonalInfo = _e[1];
        var currentCountryCode = useMemo(function () {
            return countries.find(function (country) {
                if (country.ISO_code === country_iso_code) {
                    return country;
                }
            });
        }, [country_iso_code]);
        useEffect(function () {
            if (country_iso_code) {
                setPersonalInfo(function (prevState) {
                    var _a, _b;
                    return (__assign(__assign({}, prevState), { country_code: ((_a = currentCountryCode) === null || _a === void 0 ? void 0 : _a.code) ? (_b = currentCountryCode) === null || _b === void 0 ? void 0 : _b.code : "" }));
                });
                var lang = i18n.language;
                var currency_1 = getLocalizedCurrency(lang, country_iso_code);
                setCheckoutState(function (prevState) { return (__assign(__assign({}, prevState), { currency: currency_1, dirty: false })); });
            }
        }, [country_iso_code]);
        // making sure base url is correct
        useEffect(function () {
            var _a, _b, _c, _d;
            var previewLinkIdentifier = "";
            if (preview_link) {
                // current pathname
                var currentPathname = ((_b = (_a = history) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.pathname) ? (_d = (_c = history) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.pathname : "";
                // the 'TestIntegration' in 'https://blah.blah/#/TestIntegration'
                previewLinkIdentifier =
                    preview_link.split("/")[preview_link.split("/").length - 1] || "";
                // incase user inputs a url that doesn't contain a previewLinkIdentifier
                // then we need to add it manually 💁‍♂️ then push to the new path
                if (currentPathname[0] === "/" && currentPathname[1] === "/") {
                    history.replace("/" + previewLinkIdentifier + "/" + currentPathname.substr(2, currentPathname.length));
                }
            }
            // knowing order type using the current pathname
            var splittedPathname = history.location.pathname.split("/");
            // getting order type from pathname 'local/#/TestIntegration/pickup/
            var orderTypeInPathname = splittedPathname[splittedPathname.indexOf(previewLinkIdentifier) + 1];
            if (orderTypeInPathname === "delivery" ||
                orderTypeInPathname === "pickup")
                setCheckoutState(function (prevState) { return (__assign(__assign({}, prevState), { orderType: orderTypeInPathname, dirty: false })); });
        }, [history.location.pathname, preview_link]);
        useEffect(function () {
            if (country_iso_code && store_id) {
                dispatch(getCities({
                    country_iso_code: country_iso_code.toUpperCase(),
                    store_id: store_id,
                }));
            }
            if (store_id) {
                dispatch(getPickupBranches(store_id));
            }
        }, [store_id, country_iso_code]);
        // clear prev menu selections if user trying to change the branch
        useEffect(function () {
            var currentPathname = history.location.pathname;
            if ((currentPathname.includes("cities") ||
                currentPathname.includes("districts") ||
                currentPathname.includes("sub-districts") ||
                currentPathname.includes("branches")) &&
                Object.keys(checkoutState.checkoutItemsMap).length > 0) {
                setCheckoutState(function (prevState) { return (__assign(__assign({}, prevState), { checkoutItemsMap: {}, promo_code: "", comment: "", gifts: [], dirty: false })); });
                // clear cached checkout values
                sessionStorage.removeItem(localStatesHashIds.checkoutState);
            }
        }, [history.location.pathname]);
        var _f = useRouter(), search = _f.location.search, replace = _f.replace, branch_id = _f.query.branch_id;
        // parsing the token and other params from the url
        var params = new URLSearchParams(search);
        var parsedParams = useMemo(function () {
            var parsedToken = params.get("token");
            var order_id = params.get("order_id");
            var requires_support = params.get("requires_support");
            var qr_branch_id = params.get("qr_branch_id");
            return {
                parsedToken: parsedToken,
                order_id: order_id,
                requires_support: requires_support === "true",
                qr_branch_id: qr_branch_id,
            };
        }, [search, params]);
        useDeepCompareEffect(function () {
            var order_id = parsedParams.order_id, parsedToken = parsedParams.parsedToken, requires_support = parsedParams.requires_support;
            if (order_id && parsedToken && previewId) {
                replace({
                    pathname: "/" + previewId + "/order-tracking/" + parsedToken + "/" + order_id,
                    search: "?requires_support=" + requires_support,
                });
                dispatch(getOrderStatuses({ order_id: order_id, token: parsedToken }));
                dispatch(loading());
            }
        }, [previewId, parsedParams]);
        useDeepCompareEffect(function () {
            if (parsedParams.parsedToken) {
                dispatch(getOrderDetails(parsedParams.parsedToken));
                dispatch(getUserInfo({ parsedToken: parsedParams.parsedToken }));
                dispatch(loading());
            }
        }, [parsedParams]);
        // qr portal 
        // storing qr data in session storage 
        useEffect(function () {
            if (parsedParams.qr_branch_id) {
                setQRPortalValuesInSessionStorage(function (prev) { return (__assign(__assign({}, prev), { branch_id: parsedParams.qr_branch_id, is_qr_portal: true })); });
            }
        }, [parsedParams.qr_branch_id]);
        useEffect(function () {
            if (baseURL.includes(process.env.QR_PORTAL_URL) &&
                qrPortalValuesInSessionStorage.branch_id) {
                setQRPortalState(function (prevState) { return (__assign(__assign({}, prevState), { is_qr_portal: true })); });
                dispatch(getQRBranchStatus({
                    branch_id: qrPortalValuesInSessionStorage.branch_id,
                    qr_preview_id: previewId,
                }));
                if (pathname === "/" + previewId + "/" || pathname === "/" + previewId) {
                    history.push("/" + previewId + "/?qr_branch_id=" + qrPortalValuesInSessionStorage.branch_id);
                }
            }
        }, [previewId, qrPortalValuesInSessionStorage.branch_id, pathname]);
        // checking branch status in qr portal 
        useDeepCompareEffect(function () {
            var _a, _b;
            if ((qrPortalValuesInSessionStorage.branch_id || parsedParams.qr_branch_id) && qr_branch_status.store_id && baseURL.includes(process.env.QR_PORTAL_URL)) {
                dispatch(getStoreInfo({
                    portal_type: "qr-portal",
                    store_id: qr_branch_status.store_id
                }));
                if (parsedParams.qr_branch_id &&
                    qr_branch_status.qr_dine_in_status !== PickupStatus.service_enabled &&
                    qr_branch_status.qr_take_away_status ===
                        PickupStatus.service_enabled &&
                    previewId) {
                    setQRPortalState(function (prevState) { return (__assign(__assign({}, prevState), { in_branch_order_type: "takeaway" })); });
                    setQRPortalValuesInSessionStorage(function (prev) { return (__assign(__assign({}, prev), { in_branch_order_type: "takeaway" })); });
                    setCheckoutState(function (prev) { return (__assign(__assign({}, prev), { in_branch_order_type: "take_away", orderType: "pickup" })); });
                    sessionStorage.setItem(localStatesHashIds.checkoutState, JSON.stringify(__assign(__assign({}, checkoutState), { in_branch_order_type: "take_away", orderType: "pickup" })));
                    history.push("/" + previewId + "/takeaway/menu/" + parsedParams.qr_branch_id);
                    dispatch(getMenu({
                        store_id: qr_branch_status.store_id,
                        branch_id: (_a = qrPortalValuesInSessionStorage.branch_id, (_a !== null && _a !== void 0 ? _a : parsedParams.qr_branch_id)),
                    }));
                }
                else if (parsedParams.qr_branch_id &&
                    qr_branch_status.qr_dine_in_status === PickupStatus.service_enabled &&
                    qr_branch_status.qr_take_away_status !==
                        PickupStatus.service_enabled &&
                    previewId) {
                    setQRPortalState(function (prevState) { return (__assign(__assign({}, prevState), { in_branch_order_type: "dine-in" })); });
                    setQRPortalValuesInSessionStorage(function (prev) { return (__assign(__assign({}, prev), { in_branch_order_type: "dine-in" })); });
                    setCheckoutState(function (prev) { return (__assign(__assign({}, prev), { in_branch_order_type: "dine_in", orderType: "pickup" })); });
                    sessionStorage.setItem(localStatesHashIds.checkoutState, JSON.stringify(__assign(__assign({}, checkoutState), { in_branch_order_type: "dine_in", orderType: "pickup" })));
                    history.push("/" + previewId + "/dine-in/menu/" + parsedParams.qr_branch_id);
                    dispatch(getMenu({
                        store_id: qr_branch_status.store_id,
                        branch_id: (_b = qrPortalValuesInSessionStorage.branch_id, (_b !== null && _b !== void 0 ? _b : parsedParams.qr_branch_id)),
                    }));
                }
            }
        }, [
            qr_branch_status,
            previewId,
            parsedParams.qr_branch_id,
            baseURL,
            qrPortalValuesInSessionStorage.branch_id
        ]);
        useEffect(function () {
            var _a;
            if ((_a = qrPortalValuesInSessionStorage.in_branch_order_type) === null || _a === void 0 ? void 0 : _a.trim()) {
                setQRPortalState(function (prevState) { return (__assign(__assign({}, prevState), { in_branch_order_type: ["dine_in", "dine-in"].includes(qrPortalValuesInSessionStorage.in_branch_order_type)
                        ? "dine-in"
                        : "takeaway" })); });
            }
        }, [qrPortalValuesInSessionStorage.in_branch_order_type]);
        return (React.createElement(NavigationManagerContext.Consumer, null, function (contextValue) { return React.createElement(Component, __assign({}, props, contextValue)); }));
    };
    return withNavigationManager;
};
export default NavigationManager;
