var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getStorePromotionsAction } from "../constants";
import { getStorePromotionsSuccess } from "../actions";
var initialState = {
    storePromotions: {
        pickup_promotion: {
            customers_file_path: "",
            end_time: 0,
            discount_value: 0,
            created_at: 0,
            promo_code: "",
            type: undefined,
            title: "",
            max_discount_value: 0,
            max_usage_times: 0,
            min_threshold: 0,
            start_time: 0,
            ordering_type: "",
            updated_at: 0,
            user_id: "",
            id: "",
            desc: "",
            ar_desc: "",
            ar_title: "",
            promo_category: "",
            multiplier_value: 0,
            targeting_method: "",
        },
        delivery_promotion: {
            customers_file_path: "",
            end_time: 0,
            discount_value: 0,
            created_at: 0,
            promo_code: "",
            type: undefined,
            title: "",
            max_discount_value: 0,
            max_usage_times: 0,
            min_threshold: 0,
            start_time: 0,
            ordering_type: "",
            updated_at: 0,
            user_id: "",
            id: "",
            desc: "",
            ar_desc: "",
            ar_title: "",
            promo_category: "",
            multiplier_value: 0,
        },
    },
};
export var storePromotionsReducer = withLoadingReducer(reducer(__assign({}, initialState), on(getStorePromotionsSuccess, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { storePromotions: payload }));
})), getStorePromotionsAction);
