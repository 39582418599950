import { action, payload } from "ts-action";
import { getCustomerPromotionsAction, getStorePromotionsAction, validateCustomerPromotionAction, } from "../constants";
// get store promotions actions
export var getStorePromotions = action(getStorePromotionsAction.requested, payload());
export var getStorePromotionsSuccess = action(getStorePromotionsAction.fulfilled, payload());
export var getStorePromotionsFail = action(getStorePromotionsAction.rejected, payload());
// get all promotions actions
export var getCustomerPromotions = action(getCustomerPromotionsAction.requested, payload());
export var getCustomerPromotionsSuccess = action(getCustomerPromotionsAction.fulfilled, payload());
export var getCustomerPromotionsFail = action(getCustomerPromotionsAction.rejected, payload());
// validate promocode
export var validateCustomerPromotion = action(validateCustomerPromotionAction.requested, payload());
export var validateCustomerPromotionSuccess = action(validateCustomerPromotionAction.fulfilled, payload());
export var validateCustomerPromotionFail = action(validateCustomerPromotionAction.rejected, payload());
