var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState } from "react";
import { useRouter } from "./useRouter";
import { useTranslation } from "./useTranslation";
import Strings from "../i18n/strings/header";
import CheckoutStrings from "../i18n/strings/checkout";
import { useDeepCompareEffect } from "use-deep-compare";
export var useNavigatorNames = function (location, previewId) {
    var _a;
    var t = useTranslation("header").t;
    var _b = useRouter().query, branch_id = _b.branch_id, city_id = _b.city_id, district_id = _b.district_id, store_id = _b.store_id, country_code = _b.country_code, qr_branch_id = _b.qr_branch_id, success = _b.success, table = _b.table;
    var navigatorNames = (_a = {},
        _a["/" + previewId + "/pickup/branches/" + store_id] = t(Strings.choosePreferredBranch),
        _a["/" + previewId + "/delivery"] = t(Strings.chooseYourCityAndArea),
        _a["/" + previewId + "/delivery/cities/" + country_code] = t(Strings.chooseYourCityAndArea),
        _a["/" + previewId + "/delivery/districts/" + city_id] = t(Strings.chooseYourCityAndArea),
        _a["/" + previewId + "/delivery/sub-districts/" + district_id] = t(Strings.chooseYourCityAndArea),
        _a["/" + previewId + "/"] = t(Strings.chooseOrderingMethod),
        _a["/" + previewId] = t(Strings.chooseOrderingMethod),
        _a["/" + previewId + "/?qr_branch_id=" + qr_branch_id] = t(Strings.chooseOrderingMethod),
        _a["/" + previewId + "/dine-in/table-number"] = t(CheckoutStrings.tableNumber, {
            ns: "checkout",
        }),
        _a["/" + previewId + "/pickup/menu/" + branch_id] = t(Strings.menu),
        _a["/" + previewId + "/delivery/menu/" + branch_id] = t(Strings.menu),
        _a["/" + previewId + "/dine-in/menu/" + qr_branch_id] = t(Strings.menu),
        _a["/" + previewId + "/takeaway/menu/" + qr_branch_id] = t(Strings.menu),
        _a["/" + previewId + "/pickup/personal-info"] = t(Strings.personalInfo),
        _a["/" + previewId + "/delivery/personal-info"] = t(Strings.personalInfo),
        _a["/" + previewId + "/dine-in/personal-info"] = t(Strings.personalInfo),
        _a["/" + previewId + "/takeaway/personal-info"] = t(Strings.personalInfo),
        _a["/" + previewId + "/pickup/phone-number"] = t(Strings.phoneNumber),
        _a["/" + previewId + "/delivery/phone-number"] = t(Strings.phoneNumber),
        _a["/" + previewId + "/dine-in/phone-number"] = t(Strings.phoneNumber),
        _a["/" + previewId + "/takeaway/phone-number"] = t(Strings.phoneNumber),
        _a["/" + previewId + "/delivery/verification"] = t(Strings.phoneNumberVerification),
        _a["/" + previewId + "/pickup/verification"] = t(Strings.phoneNumberVerification),
        _a["/" + previewId + "/dine-in/verification"] = t(Strings.phoneNumberVerification),
        _a["/" + previewId + "/takeaway/verification"] = t(Strings.phoneNumberVerification),
        _a["/" + previewId + "/delivery/review-order"] = t(Strings.yourOrder),
        _a["/" + previewId + "/pickup/review-order"] = t(Strings.yourOrder),
        _a["/" + previewId + "/dine-in/review-order"] = t(Strings.yourOrder),
        _a["/" + previewId + "/takeaway/review-order"] = t(Strings.yourOrder),
        _a["/" + previewId + "/delivery/checkout"] = t(Strings.yourOrder),
        _a["/" + previewId + "/pickup/checkout"] = t(Strings.yourOrder),
        _a["/" + previewId + "/dine-in/checkout"] = t(Strings.yourOrder),
        _a["/" + previewId + "/takeaway/checkout"] = t(Strings.yourOrder),
        _a["/" + previewId + "/pickup/checkout?success=" + success] = t(Strings.yourOrder),
        _a["/" + previewId + "/dine-in/checkout?success=" + success] = t(Strings.yourOrder),
        _a["/" + previewId + "/takeaway/checkout?success=" + success] = t(Strings.yourOrder),
        _a["/" + previewId + "/pickup/checkout?table=" + table] = t(Strings.yourOrder),
        _a["/" + previewId + "/dine-in/checkout?table=" + table] = t(Strings.yourOrder),
        _a["/" + previewId + "/takeaway/checkout?table=" + table] = t(Strings.yourOrder),
        _a);
    var _c = __read(useState(navigatorNames[""]), 2), routingName = _c[0], setRoutingName = _c[1];
    useDeepCompareEffect(function () {
        setRoutingName(navigatorNames[location]);
    }, [routingName, location, previewId]);
    return {
        routingName: routingName,
    };
};
