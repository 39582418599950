export default {
    delivery: "delivery",
    menu: "menu",
    choosePreferredBranch: "choosePreferredBranch",
    chooseYourCityAndArea: "chooseYourCityAndArea",
    chooseOrderingMethod: "chooseOrderingMethod",
    personalInfo: "personalInfo",
    phoneNumber: "phoneNumber",
    yourOrder: "yourOrder",
    phoneNumberVerification: "phoneNumberVerification",
    searchForCitiesAndAreas: "searchForCitiesAndAreas",
    search: "search",
    cancel: "cancel",
};
