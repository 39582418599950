export default {
    isRequired: "Required",
    deliveryTo: "Delivery to",
    pickupFrom: "Pickup from",
    promocodes: "Promocodes",
    add: "add",
    pleaseCompleteYourAddress: "Please complete your address",
    edit: "EDIT",
    complete: "COMPLETE",
    completeAddress: "Complete address",
    city: "City",
    area: "Area",
    streetNoOrName: "Street no. / name",
    buildingNo: "Building number",
    floorNo: "Floor (opt)",
    apartmentNo: "Apartment number (opt.)",
    additionalNotes: "Additional info (opt.)",
    additionalNote: "Additional Notes",
    additionalNotePlaceholder: "Tab to add any additional notes to the order...",
    addressType: "Address type",
    companyName: "Company name (opt.)",
    save: "Save",
    streetNoOrNamePlaceholder: "Type street number or name",
    floorNoPlaceholder: "Type Floor number",
    apartmentNoPlaceholder: "Type apartment number",
    additionalNotesPlaceholder: "Type any additional info",
    company: "Company",
    home: "Home",
    companyNamePlaceholder: "Type Company name",
    buildingNoPlaceholder: "Type Building number",
    minimumOrderValue: " Minimum order value is",
    reviewOrder: "Review order",
    payWith: "Pay with",
    addCardPayment: "Add card payment",
    offlinePayment: "Offline payment",
    change: "Change",
    paymentCard: "Payment card",
    addNew: "Add new",
    selectPaymentMethod: "Select a payment method",
    cod: "Cash on delivery (COD)",
    masterCard: "Master card",
    visa: "Visa",
    meza: "Meza",
    mada: "Mada",
    delivery: "Delivery",
    pickup: "Pickup",
    required: "Required*",
    checkoutButton: "Checkout",
    totalPointsUllGet: "Total Points you’ll get",
    selectOrAddPromocode: "Select or add a promocode to be applied to your order",
    pickupTime: "Pickup Time",
    deliveryTime: "Delivery Time",
    ASAP: "ASAP",
    cannotAddCard: "Can't add the card",
    enterCVV: "Enter your CVV (CSV)",
    cvvRole: " CVV (CSV) is last 3 digits in the back of your payment card",
    cvv: "CVV (CSV) is invalid",
    confirm: "Confirm",
    orderCancelled: "Order canceled due to payment issue",
    paymentFailure: " Payment failed due to connection error, please check your internet connection & try agian.",
    tryAnotherPaymentMethod: "Try another payment method",
    internetConnection: " please check your internet connection, make sure that card is not already exist & try agian.",
    tryAgain: " Try again",
    giftsAvailable: "Gifts available",
    viewAll: "View All",
    youHave: "You have",
    and: "and",
    multipleGifts: "gifts",
    singleGift: "gift",
    in: "in",
    dineIn: "Dine In",
    takeaway: "Takeaway",
    takeawayTime: "Takeaway Time",
    dineInTime: "Dine In Time",
    cash: "Cash",
    tableNumber: "Table number",
    pleaseEnterYour: "Please enter your"
};
