export default {
    wasItEasyToPickupYourOrder: "wasItEasyToPickupYourOrder",
    yes: "yes",
    no: "no",
    orderId: "Order ID:",
    help: "Help",
    alwaysHappyToServeYou: "alwaysHappyToServeYou",
    contactUS: "contactUS",
    facingAProblem: "facingAProblem",
    cancelOrder: "Cancel order",
    got_picked_up: "Got picked up",
    got_pickup_up_desc: "got_picked_up_desc",
    delivered: "Delivered",
    delivered_desc: "delivered_desc",
    out_for_delivery: "Out for delivery",
    out_for_delivery_desc: "Your order has left the kitchen, {{store_name}} couriers will deliver it to you",
    ready_for_pickup: "Ready for pickup",
    ready_for_pickup_desc: "Your order is ready, present the cashier with Order ID to enjoy pure deliciousness!",
    added_gifts_to_order: "Added Gifts to order",
    added_gifts_to_order_desc: "Added your selected gifts to items list.",
    reviewed_your_order: "Reviewing your order",
    reviewed_your_order_desc: "Your order is currently getting reviewed by the restaurant…",
    preparing_your_order: "Preparing your order",
    preparing_your_order_desc: "The guys at {{store_name}} are preparing your order",
    false: "Your order has been cancelled",
    estimatedPreparationTime: "Estimated preparation time",
    pickupFrom: "Pickup from",
    viewOnMap: "View on map",
    yourOrderDetails: "Your order details",
    subtotal: "Subtotal",
    taxesAppliedAutomatically: "Taxes applied automatically",
    estimated_delivery_time: "Estimated delivery time",
    within: "Within",
    delivery_fees: "Delivery fees",
    total: "Total",
    discount: "Discount",
    address: "Address",
    order_rejected: "Order rejected",
    order_canceled: "Order Canceled",
    order_rejected_desc: "We are sorry, but {{store_name}} couldn't accept your order.",
    order_rejected_for_payment_failure: "Rejected because of payment failure",
    cancelOrderTitle: "Cancel Order?",
    cancelOrderDesc: "Sure you want to cancel? We're sad to see you go.",
    keepIt: "Keep it",
    order_canceled_desc: "We're very sorry to inform you that {{store_name}} has cancelled your order. If you are facing any troubles please contact support.",
    pm: "PM",
    am: "AM",
    ongoingOrder: "You can’t send a new order before finishing your ongoing order",
    close: "Close",
    ready: "Ready",
    completed: "Completed",
};
