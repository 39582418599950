import { action, payload } from "ts-action";
import { createDeliveryOrderAction, createPickupOrderAction, } from "../constants";
// create delivery order
export var createDeliveryOrder = action(createDeliveryOrderAction.requested, payload());
export var createDeliveryOrderSuccess = action(createDeliveryOrderAction.fulfilled, payload());
export var createDeliveryOrderFailure = action(createDeliveryOrderAction.rejected, payload());
// create pickup order
export var createPickupOrder = action(createPickupOrderAction.requested, payload());
export var createPickupOrderSuccess = action(createPickupOrderAction.fulfilled, payload());
export var createPickupOrderFailure = action(createPickupOrderAction.rejected, payload());
