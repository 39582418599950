export var convertTimestampToDate = function (timestamp) {
    return new Date(timestamp);
};
export var convertTimestampToHrMins = function (timestamp, amString, pmString) {
    if (!timestamp)
        return null;
    var dateObj = convertTimestampToDate(timestamp);
    var hours = dateObj.getHours();
    var minutes = dateObj.getMinutes();
    var ampm = hours >= 12 ? pmString : amString;
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    var formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + formattedMinutes + " " + ampm;
};
export var convertTimestampToMins = function (timestamp, isArabic) {
    if (!timestamp)
        return;
    if (timestamp < 0)
        return isArabic ? "دقائق" : "Minutes";
    var dateObj = convertTimestampToDate(timestamp);
    var minutes = dateObj.getMinutes();
    return minutes === 0
        ? isArabic
            ? "دقائق"
            : "Minutes"
        : minutes === 1
            ? isArabic
                ? "دقيقة"
                : "Minute"
            : minutes === 2
                ? isArabic
                    ? "دقيقتين"
                    : minutes + " Minutes"
                : isArabic
                    ? minutes + " \u062F\u0642\u0627\u0626\u0642"
                    : minutes + " Minutes";
};
