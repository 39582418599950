/**
 * @description converts strings | numbers to booleans
 *
 */
export var convertToBool = function (param) { return Boolean(param); };
/**
 * @description trims a string from white spaces or from & to specific length,
 * could also add a tailing trimming decoration string
 *
 */
export var trimString = function (inputString, options) {
    if (inputString === void 0) { inputString = ""; }
    if (options === void 0) { options = { trimFrom: 0, trimTo: inputString.length, trimmingDecoration: "" }; }
    var trimFrom = options.trimFrom, trimTo = options.trimTo, trimmingDecoration = options.trimmingDecoration;
    if (inputString.length <= trimTo)
        return inputString;
    return inputString.trim().substr(trimFrom, trimTo) + trimmingDecoration;
};
