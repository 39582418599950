export default [
    {
        code: "+93",
        ISO_code: "af",
        icon: "🇦🇫",
        ar_name: "أفغانستان",
        en_name: "Afghanistan",
    },
    {
        code: "+355",
        ISO_code: "al",
        icon: "🇦🇱",
        ar_name: "ألبانيا",
        en_name: "Albania",
    },
    {
        code: "+213",
        ISO_code: "dz",
        icon: "🇩🇿",
        ar_name: "الجزائر",
        en_name: "Algeria",
    },
    {
        code: "+1 684",
        ISO_code: "as",
        icon: "🇦🇸",
        ar_name: "ساموا الأمريكية",
        en_name: "AmericanSamoa",
    },
    {
        code: "+376",
        ISO_code: "ad",
        icon: "🇦🇩",
        ar_name: "أندورا",
        en_name: "Andorra",
    },
    {
        code: "+244",
        ISO_code: "ao",
        icon: "🇦🇴",
        ar_name: "أنجولا",
        en_name: "Angola",
    },
    {
        code: "+1 264",
        ISO_code: "ai",
        icon: "🇦🇮",
        ar_name: "أنجويلا",
        en_name: "Anguilla",
    },
    {
        code: "+1268",
        ISO_code: "ag",
        icon: "🇦🇬",
        ar_name: "أنتيجوا وبربودا",
        en_name: "Antigua and Barbuda",
    },
    {
        code: "+54",
        ISO_code: "ar",
        icon: "🇦🇷",
        ar_name: "الأرجنتين",
        en_name: "Argentina",
    },
    {
        code: "+374",
        ISO_code: "am",
        icon: "🇦🇲",
        ar_name: "أرمينيا",
        en_name: "Armenia",
    },
    {
        code: "+297",
        ISO_code: "aw",
        icon: "🇦🇼",
        ar_name: "آروبا",
        en_name: "Aruba",
    },
    {
        code: "+43",
        ISO_code: "at",
        icon: "🇦🇹",
        ar_name: "النمسا",
        en_name: "Austria",
    },
    {
        code: "+994",
        ISO_code: "az",
        icon: "🇦🇿",
        ar_name: "أذربيجان",
        en_name: "Azerbaijan",
    },
    {
        code: "+1 242",
        ISO_code: "bs",
        icon: "🇧🇸",
        ar_name: "الباهاما",
        en_name: "Bahamas",
    },
    {
        code: "+973",
        ISO_code: "bh",
        icon: "🇧🇭",
        ar_name: "البحرين",
        en_name: "Bahrain",
    },
    {
        code: "+880",
        ISO_code: "bd",
        icon: "🇧🇩",
        ar_name: "بنجلاديش",
        en_name: "Bangladesh",
    },
    {
        code: "+1 246",
        ISO_code: "bb",
        icon: "🇧🇧",
        ar_name: "بربادوس",
        en_name: "Barbados",
    },
    {
        code: "+375",
        ISO_code: "by",
        icon: "🇧🇾",
        ar_name: "روسيا البيضاء",
        en_name: "Belarus",
    },
    {
        code: "+32",
        ISO_code: "be",
        icon: "🇧🇪",
        ar_name: "بلجيكا",
        en_name: "Belgium",
    },
    {
        code: "+501",
        ISO_code: "bz",
        icon: "🇧🇿",
        ar_name: "بليز",
        en_name: "Belize",
    },
    {
        code: "+229",
        ISO_code: "bj",
        icon: "🇧🇯",
        ar_name: "بنين",
        en_name: "Benin",
    },
    {
        code: "+1 441",
        ISO_code: "bm",
        icon: "🇧🇲",
        ar_name: "برمودا",
        en_name: "Bermuda",
    },
    {
        code: "+975",
        ISO_code: "bt",
        icon: "🇧🇹",
        ar_name: "بوتان",
        en_name: "Bhutan",
    },
    {
        code: "+387",
        ISO_code: "ba",
        icon: "🇧🇦",
        ar_name: "البوسنة والهرسك",
        en_name: "Bosnia and Herzegovina",
    },
    {
        code: "+267",
        ISO_code: "bw",
        icon: "🇧🇼",
        ar_name: "بتسوانا",
        en_name: "Botswana",
    },
    {
        code: "+55",
        ISO_code: "br",
        icon: "🇧🇷",
        ar_name: "البرازيل",
        en_name: "Brazil",
    },
    {
        code: "+246",
        ISO_code: "io",
        icon: "🇮🇴",
        ar_name: "المحيط الهندي البريطاني",
        en_name: "British Indian Ocean Territory",
    },
    {
        code: "+359",
        ISO_code: "bg",
        icon: "🇧🇬",
        ar_name: "بلغاريا",
        en_name: "Bulgaria",
    },
    {
        code: "+226",
        ISO_code: "bf",
        icon: "🇧🇫",
        ar_name: "بوركينا فاسو",
        en_name: "Burkina Faso",
    },
    {
        code: "+257",
        ISO_code: "bi",
        icon: "🇧🇮",
        ar_name: "بوروندي",
        en_name: "Burundi",
    },
    {
        code: "+855",
        ISO_code: "kh",
        icon: "🇰🇭",
        ar_name: "كمبوديا",
        en_name: "Cambodia",
    },
    {
        code: "+237",
        ISO_code: "cm",
        icon: "🇨🇲",
        ar_name: "الكاميرون",
        en_name: "Cameroon",
    },
    {
        code: "+238",
        ISO_code: "cv",
        icon: "🇨🇻",
        ar_name: "الرأس الأخضر",
        en_name: "Cape Verde",
    },
    {
        code: "+ 345",
        ISO_code: "ky",
        icon: "🇰🇾",
        ar_name: "جزر الكايمن",
        en_name: "Cayman Islands",
    },
    {
        code: "+236",
        ISO_code: "cf",
        icon: "🇨🇫",
        ar_name: "جمهورية افريقيا الوسطى",
        en_name: "Central African Republic",
    },
    {
        code: "+235",
        ISO_code: "td",
        icon: "🇹🇩",
        ar_name: "تشاد",
        en_name: "Chad",
    },
    {
        code: "+56",
        ISO_code: "cl",
        icon: "🇨🇱",
        ar_name: "شيلي",
        en_name: "Chile",
    },
    {
        code: "+86",
        ISO_code: "cn",
        icon: "🇨🇳",
        ar_name: "الصين",
        en_name: "China",
    },
    {
        code: "+57",
        ISO_code: "co",
        icon: "🇨🇴",
        ar_name: "كولومبيا",
        en_name: "Colombia",
    },
    {
        code: "+269",
        ISO_code: "km",
        icon: "🇰🇲",
        ar_name: "جزر القمر",
        en_name: "Comoros",
    },
    {
        code: "+242",
        ISO_code: "cg",
        icon: "🇨🇬",
        ar_name: "الكونغو - برازافيل",
        en_name: "Congo",
    },
    {
        code: "+682",
        ISO_code: "ck",
        icon: "🇨🇰",
        ar_name: "جزر كوك",
        en_name: "Cook Islands",
    },
    {
        code: "+506",
        ISO_code: "cr",
        icon: "🇨🇷",
        ar_name: "كوستاريكا",
        en_name: "Costa Rica",
    },
    {
        code: "+385",
        ISO_code: "hr",
        icon: "🇭🇷",
        ar_name: "كرواتيا",
        en_name: "Croatia",
    },
    {
        code: "+53",
        ISO_code: "cu",
        icon: "🇨🇺",
        ar_name: "كوبا",
        en_name: "Cuba",
    },
    {
        code: "+537",
        ISO_code: "cy",
        icon: "🇨🇾",
        ar_name: "قبرص",
        en_name: "Cyprus",
    },
    {
        code: "+420",
        ISO_code: "cz",
        icon: "🇨🇿",
        ar_name: "جمهورية التشيك",
        en_name: "Czech Republic",
    },
    {
        code: "+45",
        ISO_code: "dk",
        icon: "🇩🇰",
        ar_name: "الدانمرك",
        en_name: "Denmark",
    },
    {
        code: "+253",
        ISO_code: "dj",
        icon: "🇩🇯",
        ar_name: "جيبوتي",
        en_name: "Djibouti",
    },
    {
        code: "+1 767",
        ISO_code: "dm",
        icon: "🇩🇲",
        ar_name: "دومينيكا",
        en_name: "Dominica",
    },
    {
        code: "+1 849",
        ISO_code: "do",
        icon: "🇩🇴",
        ar_name: "جمهورية الدومينيك",
        en_name: "Dominican Republic",
    },
    {
        code: "+593",
        ISO_code: "ec",
        icon: "🇪🇨",
        ar_name: "الاكوادور",
        en_name: "Ecuador",
    },
    {
        code: "+2",
        ISO_code: "eg",
        icon: "🇪🇬",
        ar_name: "مصر",
        en_name: "Egypt",
    },
    {
        code: "+503",
        ISO_code: "sv",
        icon: "🇸🇻",
        ar_name: "السلفادور",
        en_name: "El Salvador",
    },
    {
        code: "+240",
        ISO_code: "gq",
        icon: "🇬🇶",
        ar_name: "غينيا الاستوائية",
        en_name: "Equatorial Guinea",
    },
    {
        code: "+291",
        ISO_code: "er",
        icon: "🇪🇷",
        ar_name: "اريتريا",
        en_name: "Eritrea",
    },
    {
        code: "+372",
        ISO_code: "ee",
        icon: "🇪🇪",
        ar_name: "استونيا",
        en_name: "Estonia",
    },
    {
        code: "+251",
        ISO_code: "et",
        icon: "🇪🇹",
        ar_name: "اثيوبيا",
        en_name: "Ethiopia",
    },
    {
        code: "+298",
        ISO_code: "fo",
        icon: "🇫🇴",
        ar_name: "جزر فارو",
        en_name: "Faroe Islands",
    },
    {
        code: "+679",
        ISO_code: "fj",
        icon: "🇫🇯",
        ar_name: "فيجي",
        en_name: "Fiji",
    },
    {
        code: "+358",
        ISO_code: "fi",
        icon: "🇫🇮",
        ar_name: "فنلندا",
        en_name: "Finland",
    },
    {
        code: "+33",
        ISO_code: "fr",
        icon: "🇫🇷",
        ar_name: "فرنسا",
        en_name: "France",
    },
    {
        code: "+594",
        ISO_code: "gf",
        icon: "🇬🇫",
        ar_name: "غويانا",
        en_name: "French Guiana",
    },
    {
        code: "+689",
        ISO_code: "pf",
        icon: "🇵🇫",
        ar_name: "بولينيزيا الفرنسية",
        en_name: "French Polynesia",
    },
    {
        code: "+241",
        ISO_code: "ga",
        icon: "🇬🇦",
        ar_name: "الجابون",
        en_name: "Gabon",
    },
    {
        code: "+220",
        ISO_code: "gm",
        icon: "🇬🇲",
        ar_name: "غامبيا",
        en_name: "Gambia",
    },
    {
        code: "+995",
        ISO_code: "ge",
        icon: "🇬🇪",
        ar_name: "جورجيا",
        en_name: "Georgia",
    },
    {
        code: "+49",
        ISO_code: "de",
        icon: "🇩🇪",
        ar_name: "ألمانيا",
        en_name: "Germany",
    },
    {
        code: "+233",
        ISO_code: "gh",
        icon: "🇬🇭",
        ar_name: "غانا",
        en_name: "Ghana",
    },
    {
        code: "+350",
        ISO_code: "gi",
        icon: "🇬🇮",
        ar_name: "جبل طارق",
        en_name: "Gibraltar",
    },
    {
        code: "+30",
        ISO_code: "gr",
        icon: "🇬🇷",
        ar_name: "اليونان",
        en_name: "Greece",
    },
    {
        code: "+299",
        ISO_code: "gl",
        icon: "🇬🇱",
        ar_name: "جرينلاند",
        en_name: "Greenland",
    },
    {
        code: "+1 473",
        ISO_code: "gd",
        icon: "🇬🇩",
        ar_name: "جرينادا",
        en_name: "Grenada",
    },
    {
        code: "+1 671",
        ISO_code: "gu",
        icon: "🇬🇺",
        ar_name: "جوام",
        en_name: "Guam",
    },
    {
        code: "+502",
        ISO_code: "gt",
        icon: "🇬🇹",
        ar_name: "جواتيمالا",
        en_name: "Guatemala",
    },
    {
        code: "+224",
        ISO_code: "gn",
        icon: "🇬🇳",
        ar_name: "غينيا",
        en_name: "Guinea",
    },
    {
        code: "+245",
        ISO_code: "gw",
        icon: "🇬🇼",
        ar_name: "غينيا بيساو",
        en_name: "Guinea-Bissau",
    },
    {
        code: "+509",
        ISO_code: "ht",
        icon: "🇭🇹",
        ar_name: "هايتي",
        en_name: "Haiti",
    },
    {
        code: "+504",
        ISO_code: "hn",
        icon: "🇭🇳",
        ar_name: "هندوراس",
        en_name: "Honduras",
    },
    {
        code: "+36",
        ISO_code: "hu",
        icon: "🇭🇺",
        ar_name: "المجر",
        en_name: "Hungary",
    },
    {
        code: "+354",
        ISO_code: "is",
        icon: "🇮🇸",
        ar_name: "أيسلندا",
        en_name: "Iceland",
    },
    {
        code: "+91",
        ISO_code: "in",
        icon: "🇮🇳",
        ar_name: "الهند",
        en_name: "India",
    },
    {
        code: "+62",
        ISO_code: "id",
        icon: "🇮🇩",
        ar_name: "اندونيسيا",
        en_name: "Indonesia",
    },
    {
        code: "+964",
        ISO_code: "iq",
        icon: "🇮🇶",
        ar_name: "العراق",
        en_name: "Iraq",
    },
    {
        code: "+353",
        ISO_code: "ie",
        icon: "🇮🇪",
        ar_name: "أيرلندا",
        en_name: "Ireland",
    },
    {
        code: "+39",
        ISO_code: "it",
        icon: "🇮🇹",
        ar_name: "ايطاليا",
        en_name: "Italy",
    },
    {
        code: "+1 876",
        ISO_code: "jm",
        icon: "🇯🇲",
        ar_name: "جامايكا",
        en_name: "Jamaica",
    },
    {
        code: "+81",
        ISO_code: "jp",
        icon: "🇯🇵",
        ar_name: "اليابان",
        en_name: "Japan",
    },
    {
        code: "+962",
        ISO_code: "jo",
        icon: "🇯🇴",
        ar_name: "الأردن",
        en_name: "Jordan",
    },
    {
        code: "+7 7",
        ISO_code: "kz",
        icon: "🇰🇿",
        ar_name: "كازاخستان",
        en_name: "Kazakhstan",
    },
    {
        code: "+254",
        ISO_code: "ke",
        icon: "🇰🇪",
        ar_name: "كينيا",
        en_name: "Kenya",
    },
    {
        code: "+686",
        ISO_code: "ki",
        icon: "🇰🇮",
        ar_name: "كيريباتي",
        en_name: "Kiribati",
    },
    {
        code: "+965",
        ISO_code: "kw",
        icon: "🇰🇼",
        ar_name: "الكويت",
        en_name: "Kuwait",
    },
    {
        code: "+996",
        ISO_code: "kg",
        icon: "🇰🇬",
        ar_name: "قرغيزستان",
        en_name: "Kyrgyzstan",
    },
    {
        code: "+371",
        ISO_code: "lv",
        icon: "🇱🇻",
        ar_name: "لاتفيا",
        en_name: "Latvia",
    },
    {
        code: "+961",
        ISO_code: "lb",
        icon: "🇱🇧",
        ar_name: "لبنان",
        en_name: "Lebanon",
    },
    {
        code: "+266",
        ISO_code: "ls",
        icon: "🇱🇸",
        ar_name: "ليسوتو",
        en_name: "Lesotho",
    },
    {
        code: "+231",
        ISO_code: "lr",
        icon: "🇱🇷",
        ar_name: "ليبيريا",
        en_name: "Liberia",
    },
    {
        code: "+423",
        ISO_code: "li",
        icon: "🇱🇮",
        ar_name: "ليختنشتاين",
        en_name: "Liechtenstein",
    },
    {
        code: "+370",
        ISO_code: "lt",
        icon: "🇱🇹",
        ar_name: "ليتوانيا",
        en_name: "Lithuania",
    },
    {
        code: "+352",
        ISO_code: "lu",
        icon: "🇱🇺",
        ar_name: "لوكسمبورج",
        en_name: "Luxembourg",
    },
    {
        code: "+261",
        ISO_code: "mg",
        icon: "🇲🇬",
        ar_name: "مدغشقر",
        en_name: "Madagascar",
    },
    {
        code: "+265",
        ISO_code: "mw",
        icon: "🇲🇼",
        ar_name: "ملاوي",
        en_name: "Malawi",
    },
    {
        code: "+60",
        ISO_code: "my",
        icon: "🇲🇾",
        ar_name: "ماليزيا",
        en_name: "Malaysia",
    },
    {
        code: "+960",
        ISO_code: "mv",
        icon: "🇲🇻",
        ar_name: "جزر الملديف",
        en_name: "Maldives",
    },
    {
        code: "+223",
        ISO_code: "ml",
        icon: "🇲🇱",
        ar_name: "مالي",
        en_name: "Mali",
    },
    {
        code: "+356",
        ISO_code: "mt",
        icon: "🇲🇹",
        ar_name: "مالطا",
        en_name: "Malta",
    },
    {
        code: "+692",
        ISO_code: "mh",
        icon: "🇲🇭",
        ar_name: "جزر المارشال",
        en_name: "Marshall Islands",
    },
    {
        code: "+596",
        ISO_code: "mq",
        icon: "🇲🇶",
        ar_name: "مارتينيك",
        en_name: "Martinique",
    },
    {
        code: "+222",
        ISO_code: "mr",
        icon: "🇲🇷",
        ar_name: "موريتانيا",
        en_name: "Mauritania",
    },
    {
        code: "+230",
        ISO_code: "mu",
        icon: "🇲🇺",
        ar_name: "موريشيوس",
        en_name: "Mauritius",
    },
    {
        code: "+52",
        ISO_code: "mx",
        icon: "🇲🇽",
        ar_name: "المكسيك",
        en_name: "Mexico",
    },
    {
        code: "+377",
        ISO_code: "mc",
        icon: "🇲🇨",
        ar_name: "موناكو",
        en_name: "Monaco",
    },
    {
        code: "+976",
        ISO_code: "mn",
        icon: "🇲🇳",
        ar_name: "منغوليا",
        en_name: "Mongolia",
    },
    {
        code: "+382",
        ISO_code: "me",
        icon: "🇲🇪",
        ar_name: "الجبل الأسود",
        en_name: "Montenegro",
    },
    {
        code: "+1664",
        ISO_code: "ms",
        icon: "🇲🇸",
        ar_name: "مونتسرات",
        en_name: "Montserrat",
    },
    {
        code: "+212",
        ISO_code: "ma",
        icon: "🇲🇦",
        ar_name: "المغرب",
        en_name: "Morocco",
    },
    {
        code: "+95",
        ISO_code: "mm",
        icon: "🇲🇲",
        ar_name: "ميانمار",
        en_name: "Myanmar",
    },
    {
        code: "+264",
        ISO_code: "na",
        icon: "🇳🇦",
        ar_name: "ناميبيا",
        en_name: "Namibia",
    },
    {
        code: "+674",
        ISO_code: "nr",
        icon: "🇳🇷",
        ar_name: "نورو",
        en_name: "Nauru",
    },
    {
        code: "+977",
        ISO_code: "np",
        icon: "🇳🇵",
        ar_name: "نيبال",
        en_name: "Nepal",
    },
    {
        code: "+31",
        ISO_code: "nl",
        icon: "🇳🇱",
        ar_name: "هولندا",
        en_name: "Netherlands",
    },
    {
        code: "+687",
        ISO_code: "nc",
        icon: "🇳🇨",
        ar_name: "كاليدونيا الجديدة",
        en_name: "New Caledonia",
    },
    {
        code: "+64",
        ISO_code: "nz",
        icon: "🇳🇿",
        ar_name: "نيوزيلاندا",
        en_name: "New Zealand",
    },
    {
        code: "+505",
        ISO_code: "ni",
        icon: "🇳🇮",
        ar_name: "نيكاراجوا",
        en_name: "Nicaragua",
    },
    {
        code: "+227",
        ISO_code: "ne",
        icon: "🇳🇪",
        ar_name: "النيجر",
        en_name: "Niger",
    },
    {
        code: "+234",
        ISO_code: "ng",
        icon: "🇳🇬",
        ar_name: "نيجيريا",
        en_name: "Nigeria",
    },
    {
        code: "+683",
        ISO_code: "nu",
        icon: "🇳🇺",
        ar_name: "نيوي",
        en_name: "Niue",
    },
    {
        code: "+672",
        ISO_code: "nf",
        icon: "🇳🇫",
        ar_name: "جزيرة نورفوك",
        en_name: "Norfolk Island",
    },
    {
        code: "+1 670",
        ISO_code: "mp",
        icon: "🇲🇵",
        ar_name: "جزر ماريانا الشمالية",
        en_name: "Northern Mariana Islands",
    },
    {
        code: "+968",
        ISO_code: "om",
        icon: "🇴🇲",
        ar_name: "عمان",
        en_name: "Oman",
    },
    {
        code: "+92",
        ISO_code: "pk",
        icon: "🇵🇰",
        ar_name: "باكستان",
        en_name: "Pakistan",
    },
    {
        code: "+680",
        ISO_code: "pw",
        icon: "🇵🇼",
        ar_name: "بالاو",
        en_name: "Palau",
    },
    {
        code: "+507",
        ISO_code: "pa",
        icon: "🇵🇦",
        ar_name: "بنما",
        en_name: "Panama",
    },
    {
        code: "+675",
        ISO_code: "pg",
        icon: "🇵🇬",
        ar_name: "بابوا غينيا الجديدة",
        en_name: "Papua New Guinea",
    },
    {
        code: "+595",
        ISO_code: "py",
        icon: "🇵🇾",
        ar_name: "باراجواي",
        en_name: "Paraguay",
    },
    {
        code: "+51",
        ISO_code: "pe",
        icon: "🇵🇪",
        ar_name: "بيرو",
        en_name: "Peru",
    },
    {
        code: "+63",
        ISO_code: "ph",
        icon: "🇵🇭",
        ar_name: "الفيلبين",
        en_name: "Philippines",
    },
    {
        code: "+48",
        ISO_code: "pl",
        icon: "🇵🇱",
        ar_name: "بولندا",
        en_name: "Poland",
    },
    {
        code: "+351",
        ISO_code: "pt",
        icon: "🇵🇹",
        ar_name: "البرتغال",
        en_name: "Portugal",
    },
    {
        code: "+1 939",
        ISO_code: "pr",
        icon: "🇵🇷",
        ar_name: "بورتوريكو",
        en_name: "Puerto Rico",
    },
    {
        code: "+974",
        ISO_code: "qa",
        icon: "🇶🇦",
        ar_name: "قطر",
        en_name: "Qatar",
    },
    {
        code: "+40",
        ISO_code: "ro",
        icon: "🇷🇴",
        ar_name: "رومانيا",
        en_name: "Romania",
    },
    {
        code: "+250",
        ISO_code: "rw",
        icon: "🇷🇼",
        ar_name: "رواندا",
        en_name: "Rwanda",
    },
    {
        code: "+685",
        ISO_code: "ws",
        icon: "🇼🇸",
        ar_name: "ساموا",
        en_name: "Samoa",
    },
    {
        code: "+378",
        ISO_code: "sm",
        icon: "🇸🇲",
        ar_name: "سان مارينو",
        en_name: "San Marino",
    },
    {
        code: "+966",
        ISO_code: "sa",
        icon: "🇸🇦",
        ar_name: "المملكة العربية السعودية",
        en_name: "Saudi Arabia",
    },
    {
        code: "+221",
        ISO_code: "sn",
        icon: "🇸🇳",
        ar_name: "السنغال",
        en_name: "Senegal",
    },
    {
        code: "+381",
        ISO_code: "rs",
        icon: "🇷🇸",
        ar_name: "صربيا",
        en_name: "Serbia",
    },
    {
        code: "+248",
        ISO_code: "sc",
        icon: "🇸🇨",
        ar_name: "سيشل",
        en_name: "Seychelles",
    },
    {
        code: "+232",
        ISO_code: "sl",
        icon: "🇸🇱",
        ar_name: "سيراليون",
        en_name: "Sierra Leone",
    },
    {
        code: "+65",
        ISO_code: "sg",
        icon: "🇸🇬",
        ar_name: "سنغافورة",
        en_name: "Singapore",
    },
    {
        code: "+421",
        ISO_code: "sk",
        icon: "🇸🇰",
        ar_name: "سلوفاكيا",
        en_name: "Slovakia",
    },
    {
        code: "+386",
        ISO_code: "si",
        icon: "🇸🇮",
        ar_name: "سلوفينيا",
        en_name: "Slovenia",
    },
    {
        code: "+677",
        ISO_code: "sb",
        icon: "🇸🇧",
        ar_name: "جزر سليمان",
        en_name: "Solomon Islands",
    },
    {
        code: "+27",
        ISO_code: "za",
        icon: "🇿🇦",
        ar_name: "جمهورية جنوب افريقيا",
        en_name: "South Africa",
    },
    {
        code: "+34",
        ISO_code: "es",
        icon: "🇪🇸",
        ar_name: "أسبانيا",
        en_name: "Spain",
    },
    {
        code: "+94",
        ISO_code: "lk",
        icon: "🇱🇰",
        ar_name: "سريلانكا",
        en_name: "Sri Lanka",
    },
    {
        code: "+249",
        ISO_code: "sd",
        icon: "🇸🇩",
        ar_name: "السودان",
        en_name: "Sudan",
    },
    {
        code: "+597",
        ISO_code: "sr",
        icon: "🇸🇷",
        ar_name: "سورينام",
        en_name: "Suriname",
    },
    {
        code: "+268",
        ISO_code: "sz",
        icon: "🇸🇿",
        ar_name: "سوازيلاند",
        en_name: "Swaziland",
    },
    {
        code: "+46",
        ISO_code: "se",
        icon: "🇸🇪",
        ar_name: "السويد",
        en_name: "Sweden",
    },
    {
        code: "+41",
        ISO_code: "ch",
        icon: "🇨🇭",
        ar_name: "سويسرا",
        en_name: "Switzerland",
    },
    {
        code: "+992",
        ISO_code: "tj",
        icon: "🇹🇯",
        ar_name: "طاجكستان",
        en_name: "Tajikistan",
    },
    {
        code: "+66",
        ISO_code: "th",
        icon: "🇹🇭",
        ar_name: "تايلند",
        en_name: "Thailand",
    },
    {
        code: "+228",
        ISO_code: "tg",
        icon: "🇹🇬",
        ar_name: "توجو",
        en_name: "Togo",
    },
    {
        code: "+690",
        ISO_code: "tk",
        icon: "🇹🇰",
        ar_name: "توكيلو",
        en_name: "Tokelau",
    },
    {
        code: "+676",
        ISO_code: "to",
        icon: "🇹🇴",
        ar_name: "تونجا",
        en_name: "Tonga",
    },
    {
        code: "+1 868",
        ISO_code: "tt",
        icon: "🇹🇹",
        ar_name: "ترينيداد وتوباغو",
        en_name: "Trinidad and Tobago",
    },
    {
        code: "+216",
        ISO_code: "tn",
        icon: "🇹🇳",
        ar_name: "تونس",
        en_name: "Tunisia",
    },
    {
        code: "+90",
        ISO_code: "tr",
        icon: "🇹🇷",
        ar_name: "تركيا",
        en_name: "Turkey",
    },
    {
        code: "+993",
        ISO_code: "tm",
        icon: "🇹🇲",
        ar_name: "تركمانستان",
        en_name: "Turkmenistan",
    },
    {
        code: "+1 649",
        ISO_code: "tc",
        icon: "🇹🇨",
        ar_name: "جزر الترك وجايكوس",
        en_name: "Turks and Caicos Islands",
    },
    {
        code: "+688",
        ISO_code: "tv",
        icon: "🇹🇻",
        ar_name: "توفالو",
        en_name: "Tuvalu",
    },
    {
        code: "+256",
        ISO_code: "ug",
        icon: "🇺🇬",
        ar_name: "أوغندا",
        en_name: "Uganda",
    },
    {
        code: "+380",
        ISO_code: "ua",
        icon: "🇺🇦",
        ar_name: "أوكرانيا",
        en_name: "Ukraine",
    },
    {
        code: "+971",
        ISO_code: "ae",
        icon: "🇦🇪",
        ar_name: "الامارات العربية المتحدة",
        en_name: "United Arab Emirates",
    },
    {
        code: "+1",
        ISO_code: "us",
        icon: "🇺🇸",
        ar_name: "الولايات المتحدة الأمريكية",
        en_name: "United States",
    },
    {
        code: "+598",
        ISO_code: "uy",
        icon: "🇺🇾",
        ar_name: "أورجواي",
        en_name: "Uruguay",
    },
    {
        code: "+998",
        ISO_code: "uz",
        icon: "🇺🇿",
        ar_name: "أوزبكستان",
        en_name: "Uzbekistan",
    },
    {
        code: "+678",
        ISO_code: "vu",
        icon: "🇻🇺",
        ar_name: "فانواتو",
        en_name: "Vanuatu",
    },
    {
        code: "+681",
        ISO_code: "wf",
        icon: "🇼🇫",
        ar_name: "جزر والس وفوتونا",
        en_name: "Wallis and Futuna",
    },
    {
        code: "+967",
        ISO_code: "ye",
        icon: "🇾🇪",
        ar_name: "اليمن",
        en_name: "Yemen",
    },
    {
        code: "+260",
        ISO_code: "zm",
        icon: "🇿🇲",
        ar_name: "زامبيا",
        en_name: "Zambia",
    },
    {
        code: "+263",
        ISO_code: "zw",
        icon: "🇿🇼",
        ar_name: "زيمبابوي",
        en_name: "Zimbabwe",
    },
    {
        code: "+591",
        ISO_code: "bo",
        icon: "🇧🇴",
        ar_name: "بوليفيا",
        en_name: "Bolivia, Plurinational State of",
    },
    {
        code: "+673",
        ISO_code: "bn",
        icon: "🇧🇳",
        ar_name: "بروناي",
        en_name: "Brunei Darussalam",
    },
    {
        code: "+61",
        ISO_code: "cc",
        icon: "🇨🇨",
        ar_name: "جزر كوكوس",
        en_name: "Cocos (Keeling) Islands",
    },
    {
        code: "+243",
        ISO_code: "cd",
        icon: "🇨🇩",
        ar_name: "جمهورية الكونغو الديمقراطية",
        en_name: "Congo, The Democratic Republic of the",
    },
    {
        code: "+225",
        ISO_code: "ci",
        icon: "🇨🇮",
        ar_name: "ساحل العاج",
        en_name: "Cote d'Ivoire",
    },
    {
        code: "+500",
        ISO_code: "fk",
        icon: "🇫🇰",
        ar_name: "جزر فوكلاند",
        en_name: "Falkland Islands (Malvinas)",
    },
    {
        code: "+379",
        ISO_code: "va",
        icon: "🇻🇦",
        ar_name: "الفاتيكان",
        en_name: "Holy See (Vatican City State)",
    },
    {
        code: "+852",
        ISO_code: "hk",
        icon: "🇭🇰",
        ar_name: "هونج كونج الصينية",
        en_name: "Hong Kong",
    },
    {
        code: "+98",
        ISO_code: "ir",
        icon: "🇮🇷",
        ar_name: "ايران",
        en_name: "Iran, Islamic Republic of",
    },
    {
        code: "+44",
        ISO_code: "je",
        icon: "🇯🇪",
        ar_name: "جيرسي",
        en_name: "Jersey",
    },
    {
        code: "+850",
        ISO_code: "kp",
        icon: "🇰🇵",
        ar_name: "كوريا الشمالية",
        en_name: "Korea, Democratic People's Republic of",
    },
    {
        code: "+82",
        ISO_code: "kr",
        icon: "🇰🇷",
        ar_name: "كوريا الجنوبية",
        en_name: "Korea, Republic of",
    },
    {
        code: "+856",
        ISO_code: "la",
        icon: "🇱🇦",
        ar_name: "لاوس",
        en_name: "Lao People's Democratic Republic",
    },
    {
        code: "+218",
        ISO_code: "ly",
        icon: "🇱🇾",
        ar_name: "ليبيا",
        en_name: "Libyan Arab Jamahiriya",
    },
    {
        code: "+853",
        ISO_code: "mo",
        icon: "🇲🇴",
        ar_name: "ماكاو الصينية",
        en_name: "Macao",
    },
    {
        code: "+389",
        ISO_code: "mk",
        icon: "🇲🇰",
        ar_name: "مقدونيا",
        en_name: "Macedonia, The Former Yugoslav Republic of",
    },
    {
        code: "+691",
        ISO_code: "fm",
        icon: "🇫🇲",
        ar_name: "ميكرونيزيا",
        en_name: "Micronesia, Federated States of",
    },
    {
        code: "+373",
        ISO_code: "md",
        icon: "🇲🇩",
        ar_name: "مولدافيا",
        en_name: "Moldova, Republic of",
    },
    {
        code: "+258",
        ISO_code: "mz",
        icon: "🇲🇿",
        ar_name: "موزمبيق",
        en_name: "Mozambique",
    },
    {
        code: "+970",
        ISO_code: "ps",
        icon: "🇵🇸",
        ar_name: "فلسطين",
        en_name: "Palestinian Territory, Occupied",
    },
    {
        code: "+872",
        ISO_code: "pn",
        icon: "🇵🇳",
        ar_name: "بتكايرن",
        en_name: "Pitcairn",
    },
    {
        code: "+262",
        ISO_code: "re",
        icon: "🇷🇪",
        ar_name: "روينيون",
        en_name: "Réunion",
    },
    {
        code: "+7",
        ISO_code: "ru",
        icon: "🇷🇺",
        ar_name: "روسيا",
        en_name: "Russia",
    },
    {
        code: "+290",
        ISO_code: "sh",
        icon: "🇸🇭",
        ar_name: "سانت هيلنا",
        en_name: "Saint Helena, Ascension and Tristan Da Cunha",
    },
    {
        code: "+1 869",
        ISO_code: "kn",
        icon: "🇰🇳",
        ar_name: "سانت كيتس ونيفيس",
        en_name: "Saint Kitts and Nevis",
    },
    {
        code: "+1 758",
        ISO_code: "lc",
        icon: "🇱🇨",
        ar_name: "سانت لوسيا",
        en_name: "Saint Lucia",
    },
    {
        code: "+590",
        ISO_code: "mf",
        icon: "🇲🇫",
        ar_name: "سانت مارتين",
        en_name: "Saint Martin",
    },
    {
        code: "+508",
        ISO_code: "pm",
        icon: "🇵🇲",
        ar_name: "سانت بيير وميكولون",
        en_name: "Saint Pierre and Miquelon",
    },
    {
        code: "+1 784",
        ISO_code: "vc",
        icon: "🇻🇨",
        ar_name: "سانت فنسنت وغرنادين",
        en_name: "Saint Vincent and the Grenadines",
    },
    {
        code: "+239",
        ISO_code: "st",
        icon: "🇸🇹",
        ar_name: "ساو تومي وبرينسيبي",
        en_name: "Sao Tome and Principe",
    },
    {
        code: "+252",
        ISO_code: "so",
        icon: "🇸🇴",
        ar_name: "الصومال",
        en_name: "Somalia",
    },
    {
        code: "+47",
        ISO_code: "sj",
        icon: "🇸🇯",
        ar_name: "سفالبارد وجان مايان",
        en_name: "Svalbard and Jan Mayen",
    },
    {
        code: "+963",
        ISO_code: "sy",
        icon: "🇸🇾",
        ar_name: "سوريا",
        en_name: "Syrian Arab Republic",
    },
    {
        code: "+886",
        ISO_code: "tw",
        icon: "🇹🇼",
        ar_name: "تايوان",
        en_name: "Taiwan, Province of China",
    },
    {
        code: "+255",
        ISO_code: "tz",
        icon: "🇹🇿",
        ar_name: "تانزانيا",
        en_name: "Tanzania, United Republic of",
    },
    {
        code: "+670",
        ISO_code: "tl",
        icon: "🇹🇱",
        ar_name: "تيمور الشرقية",
        en_name: "Timor-Leste",
    },
    {
        code: "+58",
        ISO_code: "ve",
        icon: "🇻🇪",
        ar_name: "فنزويلا",
        en_name: "Venezuela, Bolivarian Republic of",
    },
    {
        code: "+84",
        ISO_code: "vn",
        icon: "🇻🇳",
        ar_name: "فيتنام",
        en_name: "Viet Nam",
    },
    {
        code: "+1 284",
        ISO_code: "vg",
        icon: "🇻🇬",
        ar_name: "جزر فرجين البريطانية",
        en_name: "Virgin Islands, British",
    },
    {
        code: "+1 340",
        ISO_code: "vi",
        icon: "🇻🇮",
        ar_name: "جزر فرجين الأمريكية",
        en_name: "Virgin Islands, U.S.",
    },
];
