import { action, payload } from "ts-action";
import { cancelOrderAction, getOrderStatusesAction, clearPrevOrderAction, } from "../constants";
// get order statuses
export var clearPrevOrder = action(clearPrevOrderAction);
export var getOrderStatuses = action(getOrderStatusesAction.requested, payload());
export var getOrderStatusesSuccess = action(getOrderStatusesAction.fulfilled, payload());
export var getOrderStatusesFailure = action(getOrderStatusesAction.rejected, payload());
// cancel order
export var cancelOrder = action(cancelOrderAction.requested, payload());
export var cancelOrderSuccess = action(cancelOrderAction.fulfilled, payload());
export var cancelOrderFailure = action(cancelOrderAction.rejected, payload());
