export default {
    delivery: "Delivery",
    pickup: "Pickup",
    deliveryDescription: "I want my food to get delivered to me",
    pickupDescription: "I’ll pick my food up by myself",
    busy: "Busy",
    closed: "Closed",
    dineIn: "Dine In",
    takeaway: "Takeaway",
    dineInDescription: "Order food to your table",
};
